<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>员工列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片试图 -->
    <el-card class="box-card">
      <!-- 输入框 -->

      <el-row :gutter="20">
        <el-col :span="8">
          <el-input clearable placeholder="请输入内容" v-model="quertInfo.query" @clear="getUserList()">
            <el-button slot="append" icon="el-icon-search" @click="getUserList()"></el-button>
          </el-input>
        </el-col>
        <el-col :span="3">
          <!--          生成注册链接-->
          <el-button type="success" @click="generate">生成注册链接</el-button>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="userlest" border stripe>
        <el-table-column label="员工编号" prop="staff_code"></el-table-column>
        <el-table-column label="姓名" prop="user_name"></el-table-column>
        <el-table-column label="岗位" prop="job"></el-table-column>
        <el-table-column label="年龄" prop="age"></el-table-column>
        <el-table-column label="所属公司" prop="company"></el-table-column>
        <el-table-column label="工作地点" prop="city"></el-table-column>

        <el-table-column label="当前状态" prop="status"></el-table-column>
        <el-table-column label="入职时间" prop="onboarding"></el-table-column>

        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="showEditDialog(scope.row.user_id)"></el-button>
            <el-button size="mini" type="success" icon="el-icon-s-operation" @click="showSetDialog(scope.row.user_id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- //人事添加 -->
      <el-dialog title="提示" :visible.sync="dialogVisible" class="manpaodding" width="60%">
        <el-form ref="form" :model="personnelSet" label-width="100px">
          <el-row>
            <el-form-item label="入职时间">
              <el-col :span="7">
                <el-date-picker v-model="personnelSet.join_in" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" name="created_at"></el-date-picker>
              </el-col>
              <el-col style="text-align: center" :span="4">转正时间</el-col>
              <el-col :span="7">
                <el-date-picker v-model="personnelSet.turn_on" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" name="created_at"></el-date-picker>
              </el-col>
            </el-form-item>

            <el-form-item label="工资">
              <el-col :span="7">
                <el-input class="yinput" v-model="personnelSet.salary"></el-input>
                <span class="yuan">{{ wei }}</span>
              </el-col>
              <el-col style="text-align: center" :span="4"><span>试用工资</span></el-col>
              <el-col :span="7">
                <el-input class="yinput" v-model="personnelSet.salary_trial"></el-input>
                <span class="yuan">{{ wei }}</span>
              </el-col>
            </el-form-item>

            <el-form-item label="当前公司">
              <el-col :span="7">
                <el-select v-model="personnelSet.company" placeholder="请选择">
                  <el-option v-for="item in companieslist" :key="item.id" :label="item.com" :value="item.id"></el-option>
                </el-select>
              </el-col>
              <el-col style="text-align: center" :span="4"><span>工作地点</span></el-col>
              <el-col :span="7">
                <el-input class="yinput" v-model="personnelSet.city"></el-input>
              </el-col>
            </el-form-item>

            <el-form-item label="是否实习">
              <el-col :span="7">
                <el-radio-group v-model="personnelSet.is_attachment">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-col>
              <el-col style="text-align: center" :span="4"><span>部门角色</span></el-col>
              <el-col :span="7">
                <el-cascader v-model="personnelSet.role_id" :options="branchList" :props="distributionprops" clearable filterable></el-cascader>
              </el-col>
            </el-form-item>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="personnelAllocation">确 定</el-button>
        </span>
      </el-dialog>
      <!-- {{ (scope = '') }} -->

      <!-- 分页 -->
      <!-- 获取下拉框内容 -->
      <!-- 页码值改变 -->
      <!-- 当前显示的是第几页 -->
      <!-- 提供页码值 -->
      <!-- 当前每页显示多少条 -->
      <!-- 分页显示什么功能组件 -->
      <!-- 一共有几条数据 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="quertInfo.pagenum"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="quertInfo.pagesize"
        layout="total, sizes, prev, pager, next"
        :total="count"
      ></el-pagination>
    </el-card>
  </div>
</template>
<script>
import { MessageBox } from 'element-ui'
import { GetKey } from '@/api/user'
import { GetPersonnelList, GetParent } from '@/api/personnel'
import { GetgongsiList } from '@/api/connom'
import { manstatus } from '@/utils/human_resources'
import { exchangefen, exchangeyuan } from '@/utils/calculator'
import { GetAssociate } from '@/api/organization'
import Clipboard from 'clipboard'
import { AddDUserAssign } from '../../../api/personnel'
export default {
  data() {
    return {
      // 分配组织角色时的选项
      distributionprops: {
        multiple: true,
        value: 'id',
        label: 'name',
        children: 'roles',
        emitPath: false
      },

      // 状态
      manstatus,
      // 获取用户列表参数
      quertInfo: {
        query: '', // 输入框的内容
        // 当前页数
        pagenum: 1,
        // 当前页数上面的数量
        pagesize: 10
      },
      // 获取到的列表数据
      userlest: [],
      // 共有多少条
      count: 0,
      // 选择的id
      selectedRoleId: '',
      // 人事修改弹窗
      dialogVisible: false,
      // 公司列表
      companieslist: [],
      personnelSet: {
        join_in: '', // 入职时间
        turn_on: '', // 转正
        company: '', // 公司
        city: '', // 地区
        salary_trial: '', // 试用工资
        salary: '', // 工资
        userid: '', // 用户id
        role_id: [], // 角色
        is_attachment: 0 // 是否实习，默认0否
      },
      // 单位
      wei: '元',
      // 角色列表
      roleList: [],
      // 分配组织角色的数据
      branchList: []
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    async getUserList() {
      // 获取列表数据
      const { data } = await GetPersonnelList(this.quertInfo)
      if (data.code !== 200) {
        return this.$message.error('获取用户列表失败')
      }
      console.log(data.data)

      this.userlest = data.data.list // 赋值
      this.count = data.data.count // 总条数
    },
    // 获取公司列表
    async getcompaniesList() {
      const { data } = await GetgongsiList()

      this.companieslist = data.data
      console.log(data)
    },
    // 获取组织列表
    async getBranchList() {
      const { data } = await GetAssociate({ need_roles: 1 })

      this.branchList = data.data
      console.log(this.branchList)
    },
    // 生产注册链接
    async generate() {
      const { data } = await GetKey()
      if (data.code !== 200) return this.$message.error('获取注册链接失败')
      console.log(data)
      MessageBox.alert(`${window.location.host}/#/register?key=${data.data.auth_key}`, '链接', {
        confirmButtonText: '确定',
        callback: action => {
          console.log(12)
          this.$copyText(`${window.location.host}/#/register?key=${data.data.auth_key}`).then(
            function (e) {
              // 成功
            },
            function (e) {
              // 失败
            }
          )
        }
      })
    },
    async adduserasign(parameter) {
      const { data } = await AddDUserAssign(parameter)
      console.log(data)
      if (data.code === 200) {
        this.$message.success('添加成功')
      } else {
        return this.$message.error('添加失败')
      }
    },
    // 分页代码块
    // 监听pagingtion改变
    handleSizeChange(newSize) {
      this.quertInfo.pagesize = newSize
      console.log(this.quertInfo.pagesize)
      this.getUserList()
    },
    // 监听页码值改变事件
    handleCurrentChange(newPape) {
      this.quertInfo.pagenum = newPape
      console.log(this.quertInfo.pagenum)
      this.getUserList()
    },

    // 编辑部分
    // 获取修改数据
    async showEditDialog(userid) {
      console.log(userid)
      this.$router.push({
        name: 'PersonalInformation',
        params: {
          userid
        }
      })
    },
    // 设置详情
    showSetDialog(e) {
      // 获取公司列表
      this.getcompaniesList()
      // 获取部门列表

      // 部门列表缺少负责人id和负责人名称
      // 获取角色列表
      this.getBranchList()
      // 添加id给参数
      console.log(e)
      this.personnelSet.userid = e

      this.dialogVisible = true
    },
    // 人事分配
    personnelAllocation() {
      this.personnelSet.salary = exchangefen(this.personnelSet.salary)
      this.personnelSet.salary_trial = exchangefen(this.personnelSet.salary_trial)
      this.wei = '分'
      this.adduserasign(this.personnelSet)
    }
  }
}
</script>
<style lang="less" scope>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}
.yuan {
  position: absolute;
  margin-left: 10px;
}
.manpaodding {
  .el-form-item .yinput,
  .el-date-editor {
    width: 80%;
  }
  .el-form-item .yinput {
    width: 84%;
  }
}
</style>
