// 比对路有权限的方法

// 指定返回的默认路由
// allRouter前端自己的路由dynamic
// userRouter后台获取的路由权限
// realRoutes  返回最后符合的的数据

// 比对路有权限
export const recursionRouter = (userRouter = [], allRouter = []) => {
  var realRoutes = [] // 最后的数据
  allRouter.forEach((v, i) => {
    // 循环自定义权限

    userRouter.forEach((item, index) => {
      // 循环请求的权限
      if (item.name === v.meta.name) {
        // 判断返回的和自己设置的是否相等
        if (item.children && item.children.length > 0) {
          // 比对子元素
          v.children = recursionRouter(item.children, v.children) // 递归
        }
        realRoutes.push(v)
      }
    })
  })
  return realRoutes // 返回最后的数据
}

// 指定返回的默认路由

export const setDefauluRoute = routes => {
  // console.log(routes)
  routes.forEach((v, i) => {
    if (v.children && v.children.length > 0) {
      v.redirect = { name: v.children[0].name } // 指定跳转
      setDefauluRoute(v.children)
    }
  })
}
