// 引入非对称加密
import JSEncrypt from 'jsencrypt'

var pubKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDBUS3uQ+E/IZ0sY6Ak8ERIX/eW
guywrjy6ijymeWFlTQbH6x42eyEWVS92pE9sbqV4fjmOa16z3h6aWELCDVPwspOa
DJ87wo7xqAlquCli0i+d9QQhceSHIKv0FaRPdwlzLkYmUniMPhGLR7kH7i5wIRlI
AUgOJYUlET9BWbT5iQIDAQAB
-----END PUBLIC KEY-----
`
var encryptor = new JSEncrypt()
encryptor.setPublicKey(pubKey)

export { encryptor }
