<template>
  <div class="login_container">
    <div class="login_box">
      <h2>用户注册</h2>
      <el-form :model="loginFrom" :rules="loginRules" ref="loginFprmRef" label-width="0px" class="login_from">
        <!-- 姓名 -->
        <el-form-item prop="user_name">
          <el-input v-model="loginFrom.user_name" placeholder="请输入姓名" prefix-icon="el-icon-user-solid"></el-input>
        </el-form-item>
        <!-- 手机号 -->
        <el-form-item prop="mobile">
          <el-input v-model="loginFrom.mobile" placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input type="password" v-model="loginFrom.password" placeholder="请输入密码" prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button @click="ligo" type="primary">注册</el-button>
          <el-button @click="resetLoginFromRef" type="info">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
// 引入非对称加密
import { encryptor } from '@/utils/ebcryot.js'
import { Register } from '../../api/register.js'

import { steLocal } from '../../utils/local'
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/

        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      loginFrom: {
        user_name: '',
        mobile: '',
        password: '123456',
        auth_key: ''
      },
      loginRules: {
        user_name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 4, message: '长度在 2 到 4 个字符', trigger: 'blur' }
        ],
        mobile: [{ validator: checkPhone, trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  created() {
    // 获取注册的key并保存
    this.loginFrom.auth_key = this.$route.query.key
    console.log(this.auth_key)
  },
  methods: {
    // 重置表单内容
    resetLoginFromRef() {
      this.$refs.loginFprmRef.resetFields()
    },
    ligo() {
      // 注册

      this.$refs.loginFprmRef.validate(async valid => {
        if (!valid) return false // 表单预验证
        var rsaPassWord = encryptor.encrypt(this.loginFrom.password) // 加密
        this.loginFrom.password = rsaPassWord

        const { data } = await Register(this.loginFrom)
        if (data.code === 200) {
          steLocal('token', data.data.token) // 存储token
          steLocal('state', false) // 存储填写个人信息1填好0没有
          this.$message.success('注册成功')
          this.$router.push('/information')
        } else {
          alert('注册失败，失败原因：' + data.message)
        }
        console.log(data)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.login_container {
  background: #66b1ff;
  height: 100vh;
}
.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  h2 {
    text-align: center;
    margin-top: 10px;
  }
  .avatar_box {
    height: 130px;
    width: 130px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #eee;
    padding: 10px;
    box-shadow: 0 0 10px #eee;
    background-color: white;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}
.login_from {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: flex-end;
}
</style>
