// 判断权限
// 后台请求权限
import { fetchPermission } from '@/api/user'
import router from '@/router/index'
import { DynamicRouters } from '@/router/routers' // 动态路由
import dynamicRouters from '@/config/dynamic-router' // 本地验证规则
import { recursionRouter, setDefauluRoute } from '@/utils/recursion-router' // 比对、设置默认方案

export default {
  namespaced: true,
  state: {
    permissionList: null, // 最终所有路由
    sidebarMenu: [], // 导航菜单
    currentMenu: '' // 高亮
  },
  getters: {},
  mutations: {
    // 添加权限
    SET_PERMISSION(state, routes) {
      state.permissionList = routes
    },
    // 清理权限
    CLEAR_PERMISSION(state) {
      state.permissionList = null
    },
    // 设置菜单
    SET_MENU(state, menu) {
      state.sidebarMenu = menu
    },
    // 清理菜单
    CLEAR_MENU(state) {
      state.sidebarMenu = []
    }
  },
  actions: {
    async FETCH_PERMISSION({ commit, state }) {
      const {
        data: { data }
      } = await fetchPermission()
      // console.log(data)
      // 筛选
      const routes = recursionRouter(data, dynamicRouters) // 筛选（请求的，本地的）
      const MainContainer = DynamicRouters.find(v => v.path === '') // 添加子元素
      // console.log(routes)
      const children = MainContainer.children
      children.push(...routes)

      // 生成菜单
      commit('SET_MENU', children)
      // 设置默认路由
      setDefauluRoute([MainContainer])
      // 初始化路由
      const initialRoutes = router.options.routes
      router.addRoutes(DynamicRouters)
      commit('SET_PERMISSION', [...initialRoutes, ...DynamicRouters])
    }
  }
}
