var checkIdNum = (rule, value, callback) => {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  if (!value) {
    return callback(new Error('证件号码不能为空'))
  } else if (!reg.test(value)) {
    return callback(new Error('证件号码不正确'))
  } else {
    callback()
  }
}
// 手机号验证
var checkPhone = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('手机号不能为空'))
  } else {
    const reg = /^1[3|4|5|7|8][0-9]\d{8}$/

    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('请输入正确的手机号'))
    }
  }
}
// 金额验证
const validatePrice = (rule, value, callback) => {
  const reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{2})$/
  if (!value) {
    callback(new Error('金额不能为空'))
  } else if (reg.test(value)) {

    callback(new Error('请输入正确格式的金额'))

  } else if (value.length > 10) {
    callback(new Error('最多可输入10个字符'))

  } else {
    callback()
  }
}

// 验证规则
const rules = {
  // 姓名
  name: [
    { required: true, message: '请输入姓名', trigger: 'blur' },
    { min: 2, message: '请填写正确格式', trigger: 'blur' }
  ],
  // 手机号
  mobile: [{ required: true, validator: checkPhone, trigger: 'blur' }],
  // 身份证号
  identity: [{ required: true, validator: checkIdNum, trigger: 'blur' }],
  // 民族
  nation: [{ required: true, message: '请选择民族', trigger: 'blur' }],
  // 籍贯
  native_place: [{ required: true, message: '请选择籍贯', trigger: 'blur' }],
  // 婚姻状况
  marriage: [{ required: true, message: '请选择婚姻', trigger: 'blur' }],
  // 学历状况
  education: [{ required: true, message: '请选择学历', trigger: 'blur' }],
  // 户口性质
  household_type: [{ required: true, message: '请选择户口性质', trigger: 'blur' }],
  // 驾照类型
  license: [{ required: true, message: '请选择驾照类型', trigger: 'blur' }],
  // 服装
  size_designation: [{ required: true, message: '请选择服装号码', trigger: 'blur' }],
  // 鞋
  footwear_size: [{ required: true, message: '请选择鞋码', trigger: 'blur' }],
  // 现住址
  present_address: [{ required: true, message: '请输入现住址', trigger: 'blur' }],
  // 家庭关系
  relation: [{ required: true, message: '请输入关系', trigger: 'blur' }],
  // 生日
  birthday: [{ required: true, message: '请选择出生日期', trigger: 'blur' }],
  // 工作单位
  station: [{ required: true, message: '请输入工作单位', trigger: 'blur' }],
  // 毕业院校
  school: [{ required: true, message: '请输入毕业院校', trigger: 'blur' }],
  // 起止时间
  date: [{ required: true, message: '请选择起止时间', trigger: 'blur' }],
  // 所学专业
  major: [{ required: true, message: '请输入所学专业', trigger: 'blur' }],
  // 单位名称
  company: [{ required: true, message: '请输入单位名称', trigger: 'blur' }],
  // 薪资待遇
  position: [{ required: true, message: '请输入职位', trigger: 'blur' }],
  // 起止时间
  remuneration: [
    { required: true, message: '请输入薪资待遇', trigger: 'blur' },
    { type: 'number', message: '薪资待遇必须为数字值' }
  ],
  // 所学专业
  cause: [{ required: true, message: '请输入离职原因', trigger: 'blur' }],
  // 银行卡号
  kahao: [{ type: 'number', message: '银行卡号必须为数字值' }],
  // 合同签订时间
  sign_date: [{ required: true, message: '请选择日期', trigger: 'blur' }],
  // 福利项目
  item: [{ required: true, message: '请输入内容', trigger: 'blur' }],
  content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
  // 签订原因
  reason: [{ required: true, message: '请输入内容', trigger: 'blur' }],
  // 数字格式
  cost: [{ required: true, trigger: 'blur', validator: validatePrice }]
}

export { rules }
