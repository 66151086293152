import axios from './index'

// 售后工单列表
export const Getgonglist = params => {
  return axios.request({
    url: '/sale/reimburse/list',
    method: 'get',
    params
  })
}
// 售后人员列表
export const Getren = params => {
  return axios.request({
    url: '/sale/users',
    method: 'get',
    params
  })
}

// 售后筛选列表
export const Getshai = params => {
  return axios.request({
    url: '/v3/reimburse/form',
    method: 'get',
    params
  })
}

// 报销记录列表
export const GetJilulist = params => {
  return axios.request({
    url: '/ticket/reimburse/all',
    method: 'get',
    params
  })
}
// 报销记录详情
export const GetJiluDetail = params => {
  return axios.request({
    url: '/ticket/reimburse/item',
    method: 'get',
    params
  })
}
// 报销记录导出
export const GetJiluExport = params => {
  return axios.request({
    url: '/ticket/reimburse/export2xlsx',
    method: 'get',
    params,
    responseType: 'blob'
  })
}
// 报销记录筛选
export const GetJiluSearch = params => {
  return axios.request({
    url: '/ticket/reimburse/conditions',
    method: 'get',
    params
  })
}
// 表单审批
export const FormPass = data => {
  return axios.request({
    url: '/ticket/reimburse/check-form',
    method: 'post',
    data,
  })
}
// 票据审批
export const BillPass = data => {
  return axios.request({
    url: '/ticket/reimburse/check-invoice',
    method: 'post',
    data,
  })
}
// 汇总列表
export const GetHuizongList = params => {
  return axios.request({
    url: '/ticket/reimburse/statistic',
    method: 'get',
    params
  })
}
