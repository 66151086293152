import axios from '@/api/index'
// 添加组织
export const AddAssociate = data => {
  return axios.request({
    url: '/associate/add',
    method: 'post',
    data
  })
}
// 获取组织列表
export const GetAssociate = params => {
  return axios.request({
    url: '/associate/get',
    method: 'get',
    params
  })
}
// 修改组织链式关系
export const EditAssociate = data => {
  return axios.request({
    url: '/associate/edit',
    method: 'post',
    data
  })
}
// 获取组织链式关系
export const OneAssociate = params => {
  return axios.request({
    url: '/associate/one',
    method: 'get',
    params
  })
}
// 删除组织
export const DelAssociate = data => {
  return axios.request({
    url: `/associate/del?id=${data}`,
    method: 'get'

  })
}

// 给组织添加角色
export const AddDistributionRole = data => {
  return axios.request({
    url: '/associate/roles',
    method: 'post',
    data
  })
}
