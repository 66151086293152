<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>

      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限信息</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-button type="primary" @click="addRolesName()">添加权限</el-button>
      <el-table :data="tableData" style="width: 100%" :row-style="{ height: '50px' }" row-key="id" border lazy :tree-props="{ children: 'list', hasChildren: 'hasChildren' }">
        <el-table-column prop="menu_name" label="权限名称" width="500"></el-table-column>
        <el-table-column prop="menu_desc" label="权限描述" width="300"></el-table-column>
        <el-table-column prop="menu_tag" label="唯一标识" width="300"></el-table-column>
        <el-table-column prop="address" label="操作" width="169">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editfun(scope.row.id)"></el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加权限弹窗 -->
    <el-dialog title="权限管理" :visible.sync="dialogFormVisible" width="40%" :before-close="tianhandleClose">
      <el-form :model="addeForm" ref="addeForm" :rules="rolesRules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="权限名称" prop="name" label-width="150px">
          <div class="inp"><el-input v-model="addeForm.name"></el-input></div>
        </el-form-item>

        <el-form-item label="权限标识" prop="tag" label-width="150px">
          <div class="inp"><el-input v-model="addeForm.tag"></el-input></div>
        </el-form-item>

        <el-form-item label="上级归属" prop="parents" label-width="150px">
          <div class="inp"><el-input v-model="addeForm.parents" @focus="abc()" placeholder="不选择默认顶级"></el-input></div>
        </el-form-item>

        <el-form-item label="权限描述" prop="desc" label-width="150px">
          <div class="inp"><el-input v-model="addeForm.desc"></el-input></div>
        </el-form-item>

        <!-- <el-collapse v-model="activeNames">
          <el-collapse-item title="角色菜单" name="1">
            <el-tree
              ref="treeForm"
              default-expand-all
              node-key="id"
              :data="rightlist"
              :props="treeProps"
              show-checkbox
              :check-strictly="true"
              @check="handleCheckChangeArea"
            ></el-tree>
          </el-collapse-item>
        </el-collapse> -->
        <!-- {{ addeForm }}----- -->
        <!-- {{ dfrom }} -->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelfun()">取 消</el-button>
        <el-button type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="权限归属" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <!-- <el-tree
        ref="terrRef"
        :data="rightlist"
        :props="treeProps"
        show-checkbox
        highlight-current
        :check-strictly="true"
        node-key="id"
        default-expand-all
        @check-change="handleCheckChange"
      ></el-tree> -->

      <el-tree
        ref="treeForm"
        default-expand-all
        node-key="id"
        :data="rightlist"
        :props="treeProps"
        show-checkbox
        :check-strictly="true"
        @check="handleCheckChangeArea"
      ></el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quanxiao()">取 消</el-button>
        <el-button type="primary" @click="quanque()">确定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog title="权限管理" :visible.sync="bianVisible" width="40%" :before-close="bianhandleClose">
      <el-form :model="dfrom" ref="dfrom" :rules="rolesRules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="权限名称" prop="name" label-width="150px">
          <div class="inp"><el-input v-model="dfrom.name"></el-input></div>
        </el-form-item>

        <el-form-item label="权限标识" prop="tag" label-width="150px">
          <div class="inp"><el-input v-model="dfrom.tag"></el-input></div>
        </el-form-item>

        <el-form-item label="上级归属" prop="parents" label-width="150px">
          <div class="inp"><el-input v-model="dfrom.parents" @focus="abcs()" placeholder="不选择默认顶级"></el-input></div>
        </el-form-item>

        <el-form-item label="权限描述" prop="desc" label-width="150px">
          <div class="inp"><el-input v-model="dfrom.desc"></el-input></div>
        </el-form-item>
        <!-- {{ dfrom }} -->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="endxiu()">取 消</el-button>
        <el-button type="primary" @keyup.enter.native="endque()" @click="endque(tantypes)">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="权限归属" :visible.sync="listss" width="30%" :before-close="quanlist">
      <el-tree
        ref="treeDorm"
        default-expand-all
        node-key="id"
        :data="rightlist"
        :props="defaultProps"
        show-checkbox
        :check-strictly="true"
        @check="handleCheckChangeAreas"
        :default-checked-keys="[this.huixianid]"
      ></el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="xiuquanxiao()">取 消</el-button>
        <el-button type="primary" @click="xiuquanque()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Cailist, Xinlieshu, Onetiao, Shandan, Endxius } from '../../../api/quan'
import { rolesRules } from '@/utils/roleRules'
export default {
  data() {
    return {
      huixianid: '',             // 回显的数据
      labvalojb: {},
      sss: {},  
      listss: false,              
      tableData: [],             // 表格数据
      rightlist: [],             // 树状数据
      defKeys: [],               // 选中数据
      dialogFormVisible: false,  // 添加弹窗
      dialogVisible: false,      //  选中权限弹窗
      bianVisible: false,        // 编辑弹窗
      tantype: 'add',
      tantypes: 'end',
      // activeNames: ['1'],
      addeForm: {
        name: '',                 // 名称
        tag: '',                  // 标签
        parent: 0,                // 父级菜单
        parents: '',
        desc: ''                  // 描述
      },
      dfrom: {
        name: '',                 // 名称
        tag: '',                  // 标签
        parent: 0,                // 父级菜单
        parents: '',
        desc: '',                 // 描述
        id: ''
      },
      // 验证规则
      rolesRules,
      // 树形控件属性绑定
      treeProps: {
        label: 'menu_name',
        children: 'list'
      },
      defaultProps: {
        label: 'menu_name',
        children: 'list'
      }
    }
  },
  mounted() {
    this.Cadanilist()
  },
  methods: {
    getCheckedNodes() {
      console.log(this.$refs.treeDorm.getCheckedNodes())
    },
    editfun(id) {
      // console.log(id)
      this.Onetiaos(id)
      this.bianVisible = true
    },
    delfun(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delrefuelingfun(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    handleCheckChangeAreasss(data, checked, indeterminate) {
      this.$refs.treeDorm.setCheckedKeys([]) //删除所有选中节点
      // console.log(data, checked)
      // if (checked) {
      //   this.addeForm.parent = data.id
      //   this.addeForm.parents = data.menu_name
      //   this.$refs.terrRef.setCheckedKeys([data.id])
      // }
      // var getlist = this.$refs.terrRef.getCheckedNodes().concat(this.$refs.terrRef.getHalfCheckedNodes())
      // // 循环遍历当前节点的值
      // for (var i in getlist) {
      //   // 判断子节点是否存在子节点 如果存在直接请求并且提示 false
      //   if (!getlist[i].hasOwnProperty('children')) {
      //     // 判断是否只选择一个 如果存在直接请求并且提示 false
      //     if (getlist.length == 1) {
      //       this.addeForm.parent = getlist[0].id
      //       this.addeForm.parents = getlist[0].menu_name
      //       console.log(getlist[0].id)
      //       console.log(getlist[0].menu_name)
      //     } else {
      //       this.$message.error('只选择一个节点')
      //       this.$refs.terrRef.setCheckedKeys([])
      //       this.addeForm.parent = ''
      //       this.addeForm.parents = ''
      //       return
      //     }
      //   }
      // }
    },
    //当复选框被点击的时候触发
    handleCheckChangeArea(data) {
      var labvalojb = data //暂存选中节点
      // console.log(data);
      this.$refs.treeForm.setCheckedKeys([]) //删除所有选中节点
      this.$refs.treeForm.setCheckedNodes([labvalojb]) //选中已选中节点
      // this.$refs.treeForm.setCheckedNodes([labvalojb]) //选中已选中节点
      console.log(labvalojb)
      this.addeForm.parent = labvalojb.id
      this.addeForm.parents = labvalojb.menu_name
    },
    //当复选框被点击的时候触发
    handleCheckChangeAreas(data) {
      this.huixianid = data.id
      console.log(data)
      this.labvalojb = data //暂存选中节点
      // console.log(data);
      this.$refs.treeDorm.setCheckedKeys([]) //删除所有选中节点
      // this.$refs.treeDorm.setCheckedNodes([this.dfrom.id]) //选中已选中节点
      this.$refs.treeDorm.setCheckedNodes([this.labvalojb]) //选中已选中节点
      console.log(this.labvalojb)
      // this.dfrom.parent = this.labvalojb.id
      // this.dfrom.parents = this.labvalojb.menu_name
    },

    // 输入框点击事件
    abc() {
      // console.log(1111)
      this.dialogVisible = true
    },
    // 输入框点击事件
    abcs() {
      // console.log(1111)
      this.listss = true
      this.handleCheckChangeAreasss()
    },
    // 添加角色按钮
    addRolesName() {
      this.dialogFormVisible = true
      this.Cadanilist()
    },
    // 添加取消
    cancelfun() {
      this.dialogFormVisible = false
      this.$refs.addeForm.resetFields()
      this.addeForm.parent = 0
    },
    // 修改取消
    endxiu() {
      this.bianVisible = false
      this.$refs.dfrom.resetFields()
      this.dfrom.parent = 0
      this.dfrom.parents = ''
    },
    // 权限关闭弹窗
    handleClose() {
      this.dialogVisible = false
    },
    quanlist() {
      this.listss = false
      this.labvalojb = {}
      this.labvalojb.id = ''
    },
    bianhandleClose() {
      this.bianVisible = false
    },
    // 添加关闭弹窗
    tianhandleClose() {
      this.dialogFormVisible = false
      this.cancelfun()
    },
    // 选择权限取消
    quanxiao() {
      this.dialogVisible = false
      this.addeForm.parent = 0
      this.addeForm.parents = ''
      this.$refs.treeForm.setCheckedKeys([])
    },
    // 选择权限取消
    xiuquanxiao() {
      this.listss = false
      this.huixianid = this.labvalojb.parent_id
      this.$refs.treeDorm.setCheckedKeys([])
    },
    // 选择权限确认
    quanque() {
      this.dialogVisible = false
    },
    xiuquanque() {
      this.listss = false
      this.dfrom.parent = this.labvalojb.id
      this.dfrom.parents = this.labvalojb.menu_name
    },
    // 确认添加
    addgoto(type) {
      switch (type) {
        case 'add':
          // console.log(type)
          this.$refs['addeForm'].validate(v => {
            if (v) {
              this.ClientInformations(this.addeForm)
            }
          })
          break
      }
    },
    // 确认添加
    endque(type) {
      switch (type) {
        case 'end':
          // console.log(type)
          this.$refs['dfrom'].validate(v => {
            if (v) {
              this.Bianjidan(this.dfrom)
            }
          })
          break
      }
    },

    ////////////请求区
    // 列表数
    async Cadanilist() {
      const { data } = await Cailist()
      this.rightlist = data.data
      this.tableData = data.data
      // console.log(this.tableData)
      // this.getanpostfun(data, '获取')
    },

    // 添加
    async ClientInformations(v) {
      const { data } = await Xinlieshu(v)
      // console.log(data)
      this.getanpostfun(data, '添加', this.cancelfun(), this.Cadanilist())
    },
    // 编辑
    async Bianjidan(v) {
      const { data } = await Endxius(v)
      // console.log(data)
      this.getanpostfun(data, '修改', this.endxiu(), this.Cadanilist())
    },

    // 权限单条
    async Onetiaos(id) {
      const { data } = await Onetiao({ id })
      // console.log(data)
      this.dfrom.name = data.data.info.menu_name // 名称
      this.dfrom.tag = data.data.info.menu_tag // 标签
      this.dfrom.parent = data.data.info.parent_id // 父级菜单
      // this.dfrom.parents = data.data.info.menu_name // 父级菜单
      this.dfrom.id = data.data.info.id
      this.dfrom.desc = data.data.info.menu_desc // 描述
      this.labvalojb = data.data.info // 描述
      // console.log(this.labvalojb)
      if (this.labvalojb.parent_id == 0) {
        this.huixianid = 0
        this.dfrom.parents = ''
      } else {
        this.huixianid = this.labvalojb.parent_id
        this.dfrom.parents = this.labvalojb.parent_name
      }
    },

    // 删除
    async delrefuelingfun(id) {
      const { data } = await Shandan({ id })
      this.getanpostfun(data, '删除', this.Cadanilist())
    },

    // 公共方法
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style>
.inp {
  width: 280px;
}
</style>