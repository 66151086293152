import { loginAp } from '@/api/user.js'
import { getToken, steToken } from '@/utils'
import { getLocal, steLocal } from '@/utils/local'
const state = {
  // 用户名
  userName: 'Lison',
  // 权限
  rules: {},
  // token

  get UserToken() {
    return getLocal('token')
  },
  set UserToken(value) {
    return localStorage.setItem('token', value)
  }
}
const getters = {
  firstLetter: state => {
    return state.userName.substr(0, 1)
  }
}
const mutations = {
  SET_USER_NAME(state, params) {
    state.userName = params
  },
  SET_RULES(state, rules) {
    state.rules = rules
  },
  LOGIN_IN(state, token) {
    state.UserToken = token
  },
  LOGIN_OUT(state) {
    state.UserToken = ''
  }
}
const actions = {
  login({ commit }, list) {
    return new Promise((resolve, reject) => {
      loginAp(list)
        .then(res => {
          const token = res.data.data.token
          if (res.status === 200 && token) {
            commit('LOGIN_IN', token)
            resolve()
          } else {
            reject(new Error('错误'))
          }

          console.log(commit)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
