const actions = {
  // async updateAppName ({ commit }) {
  //   try {
  //     const { info: { appName } } = await getAppName()
  //     commit('SET_APP_NAME', appName)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
}
export default actions
