<template>
  <div class="vehicle_container">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>保险列表</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>

export default {
  data() {
    return {

    }
  },

  methods: {


  }
}
</script>
<style lang="less" scoped>

</style>
