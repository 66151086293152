import Login from '../views/login/index'
import NotFount from '@/views/errorPage/404'
import Forbidden from '@/views/errorPage/403'
import Layout from '@/views/layout'
import Register from '@/views/register/index'
// 人力资源
import PersonnelList from '@/views/personnel/personnel-list/index' // 人员列表
import Divisional from '@/views/personnel/divisional/index' // 部门列表
import PersonnelRole from '@/views/personnel/personnel-role/index' // 权角色列表
import PersonalInformation from '@/views/personnel/personal-information/index' // 个人信息
import Jurisdiction from '../views/personnel/jurisdiction/index'
import Fenpei from '../views/personnel/fenpei/index'
// 车辆模块
import Insurance from '@/views/vehicle/insurance' // 保险信息
import Gasoline from '@/views/vehicle/gasoline' // 加油卡信息
import Cost from '@/views/vehicle/cost' // 费用信息
import Dispatch from '@/views/vehicle/dispatch' // 调度信息
// import Collection from '@/views/vehicle/collection' // 调度信息
import Autocongin from '@/views/vehicle/autoconfigure' // 配置
export default [
  // 初始化路由
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import('@/views/information/index')
  },
  {
    path: '/phone',
    name: 'Pphone',
    component: () => import('@/views/information/phone')
  },
  {
    path: '/cesh',
    name: 'cesh',
    component: () => import('@/views/ceshi')
  },

  // 通过改变layout中的序号修改左侧展示
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      // 人员列表
      { path: 'personnel-list', component: PersonnelList },
      // 部门列表
      { path: 'divisional', component: Divisional },
      // 权限
      { path: 'jurisdiction', component: Jurisdiction },
      // 分配权限
      { path: 'allocation', component: Fenpei },
      // 角色列表
      { path: 'personnel-role', component: PersonnelRole },
      // 个人信息
      { path: 'personal-information', name: 'PersonalInformation', component: PersonalInformation },
      // 车辆信息
      { path: 'autocongin', name: 'Autocongin' }, // 配置
      { path: 'brand', name: 'Brand', component: () => import('@/views/vehicle/autoconfigure/brand.vue') },
      { path: 'supplier', name: 'Supplier', component: () => import('@/views/vehicle/autoconfigure/supplier.vue') },
      { path: 'adaptivemodel', name: 'AdaptiveModel', component: () => import('@/views/vehicle/autoconfigure/adaptiveModel.vue') },
      { path: 'productcategory', name: ' ', component: () => import('@/views/vehicle/autoconfigure/productCategory.vue') },
      { path: 'batch', name: 'Batch', component: () => import('@/views/vehicle/autoconfigure/batch.vue') },
      { path: 'drawing', name: 'Drawing', component: () => import('@/views/vehicle/autoconfigure/drawing.vue') }, // 图纸
      { path: 'parts', name: 'Parts', component: () => import('@/views/vehicle/autoconfigure/parts.vue') }, // 配件
      // 车辆信息模块、
      // 12s
      // 车辆列表
      { path: 'carinfo', name: 'Carinfo', component: () => import('@/views/vehicle/car/carinfo') },
      { path: 'carindetail/:id?', name: 'Carindetail', component: () => import('../views/vehicle/car/carindetail') },
      { path: 'lease', name: 'Lease', component: () => import('@/views/vehicle/car/lease') }, // 租赁
      { path: 'carlist', name: 'Carlist', component: () => import('@/views/vehicle/car/index') }, // 车牌
      { path: 'plate', name: 'Drawing', component: () => import('@/views/vehicle/car/plate') }, // 车牌
      { path: 'cartype', name: 'Parts', component: () => import('@/views/vehicle/car/carType') }, // 类型
      { path: 'everyone', name: 'Everyone', component: () => import('@/views/vehicle/car/everyone') }, // 类型
      { path: 'company', name: 'Company', component: () => import('@/views/vehicle/insurance/company') }, // 保险
      { path: 'addinsu', name: 'Addinsu', component: () => import('@/views/vehicle/cost/addinsu') }, // 添加保险
      { path: 'insuranceclass', name: 'Insuranceclass', component: () => import('@/views/vehicle/insurance/insuranceclass') }, // 保险类型
      { path: 'insureregister', name: 'Insureregister', component: () => import('@/views/vehicle/insurance/insureregister') }, // 保险出险
      { path: 'gasoline', name: 'Gasoline', component: Gasoline }, // 加油卡
      { path: 'cost', name: 'Cost', component: Cost }, // 费用
      { path: 'comerecord', name: 'Comerecord', component: () => import('@/views/vehicle/cost/comerecord') }, // 添加加油记录
      { path: 'comeonaudit', name: 'Comeonaudit', component: () => import('@/views/vehicle/cost/comeonaudit') }, // 加油审核
      { path: 'analyse', name: 'Analyse', component: () => import('@/views/vehicle/cost/analyse') }, // 加油审核
      { path: 'dispatch', name: 'Dispatch', component: Dispatch }, // 调度申请
      { path: 'dispjilu', name: 'Dispjilu', component: () => import('@/views/vehicle/dispatch/dispjilu') }, // 调度记录
      { path: 'dispshen', name: 'Dispshen', component: () => import('@/views/vehicle/dispatch/dispshen') }, // 调度记录
      { path: 'manager', name: 'Manager', component: () => import('@/views/vehicle/collection/manager') }, // 调度记录
      // 合同
      { path: 'client', name: 'Client', component: () => import('@/views/vehicle/contract/client') }, // 合同
      { path: 'slo', name: 'Slo', component: () => import('@/views/vehicle/contract/slo') }, // 合同
      { path: 'aftersale', name: 'Aftersale', component: () => import('@/views/vehicle/shouhou/baoxiao') }, // 售后工单
      { path: 'paperBills', name: 'PaperBills', component: () => import('@/views/vehicle/shouhou/piaoju') }, // 售后工单
      { path: 'reimbursementRecord', name: 'ReimbursementRecord', component: () => import('@/views/vehicle/shouhou/jilu') }, // 售后工单
      { path: 'reimbursementCollect', name: 'ReimbursementCollect', component: () => import('@/views/vehicle/shouhou/huizong') }, // 售后工单

      // 年审
      { path: 'annual', name: 'Annual', component: () => import('@/views/vehicle/annual/nianshenlist') }, // 年审信息
      { path: 'annualru', name: 'Annualru', component: () => import('@/views/vehicle/annual/nianshenru') }, // 年审信息
      // 车辆维修
      { path: 'maintain', name: 'Maintain', component: () => import('@/views/vehicle/car/carmaintain') }, // 维修信息
      { path: 'close', name: 'Close', component: () => import('@/views/vehicle/car/carclose') }, // 维修结算
      { path: 'repair', name: 'Repair', component: () => import('@/views/vehicle/car/carrepair') }, // 维修结算
      { path: 'store', name: 'Store', component: () => import('@/views/vehicle/store-info/storexin') }, // 仓库信息
      { path: 'mountings', name: 'Mountings', component: () => import('@/views/vehicle/store-info/mountings') }, // 配件信息
      { path: 'purchasing', name: 'Purchasing', component: () => import('@/views/vehicle/store-info/purchasing') }, // 采购 对公
      { path: 'business', name :'Business', component: () => import('@/views/vehicle/store-info/business')},   // 供应商业务管理
      { path: 'purshensing', name: 'Purshensing', component: () => import('@/views/vehicle/store-info/purshensing') }, // 采购审批
      { path: 'buyer', name: 'Buyer', component: () => import('@/views/vehicle/store-info/buyer') }, // 采购配件
      { path: 'accestype', name: 'Accestype', component: () => import('@/views/vehicle/store-info/accestype') }, // 配件类型
      { path: 'hereview', name: 'Hereview', component: () => import('@/views/vehicle/store-info/hereview') }, // 合同审核
      { path: 'inventory', name: 'Inventory', component: () => import('@/views/vehicle/store-info/inventory') }, // 公共库存状态
      { path: 'kuwarning', name: 'Kuwarning', component: () => import('@/views/vehicle/store-info/kuwarning') }, // 库存预警
      { path: 'storebatch', name: 'Storebatch', component: () => import('@/views/vehicle/store-info/storebatch') }, // 批次管理
      { path: 'rulist', name: 'Rulist', component: () => import('@/views/vehicle/store-info/rulist') }, // 出库记录
      { path: 'chulist', name: 'Chulist', component: () => import('@/views/vehicle/store-info/chulist') }, // 入库记录
      { path: 'deploy', name: 'Deploy', component: () => import('@/views/vehicle/store-info/deploy') }, // 配件调配
      { path: 'order', name: 'Order', component: () => import('@/views/vehicle/afterOrder/order.vue') }, // 售后订单列表
      { path: 'service', name: 'Service', component: () => import('@/views/vehicle/afterOrder/service.vue') }, // 售后订单列表
      { path: 'repairbill', name: 'Repairbill', component: () => import('@/views/vehicle/afterOrder/repairbill.vue') }, // 维修单审批列表
      { path: 'billof', name: 'Billof', component: () => import('@/views/vehicle/afterOrder/billof.vue') }, // 用料单审批列表
      { path: 'organization', name: 'Organization', component: () => import('@/views/personnel/organization/index.vue') }, // 组织架构
      { path: 'relevanceOrg', name: 'RelevanceOrg', component: () => import('@/views/personnel/relevanceOrg/index.vue') }, // 组织架构 
      { path: 'position', name: 'Position', component: () => import('@/views/personnel/position/index.vue') }, 
      { path: 'employeeSet', name: 'EmployeeSet', component: () => import('@/views/personnel/EmployeeSet/index.vue') }, 
      { path: 'rank', name: 'rank', component: () => import('@/views/personnel/rank/index.vue') },   // 职级

    ]
  }
]
// 动态加载路由
// export const DynamicRouters = [
//   {
//     path: '',
//     component: Layout,
//     name: 'container',
//     redirect: 'Home',
//     meta: {
//       requiresAuth: true,
//       name: '首页'
//     },
//     children: [
//       {
//         path: 'home',
//         component: Home,
//         name: 'Home',
//         meta: {
//           name: '首页',
//           icon: 'icon-name'
//         }
//       }
//     ]
//   },
//   {
//     path: '/403',
//     component: Forbidden
//   },
//   {
//     path: '*',
//     component: NotFount
//   }
// ]
