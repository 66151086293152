import axios from './index'
// 获取公司列表
export const GetgongsiList = data => {
  return axios.request({
    url: '/system/companies',
    method: 'get',
    data
  })
}

