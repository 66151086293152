<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车辆调动</el-breadcrumb-item>
      <el-breadcrumb-item>调动申请</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 添加按钮 -->
    <el-card class="box-card">
      <div style="display: flex">
        <el-button style="margin-right: 15px" type="primary" @click="opentan()">车辆调动申请</el-button>
      </div>
      <!-- 对话弹窗 -->
      <el-dialog title="调动申请" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
        <el-form ref="dform" :model="dform" label-width="100px" :rules="drules">
          <!-- 申请项目 -->
          <el-form-item label="申请项目" prop="current_org" label-width="150px">
            <el-select v-model="dform.current_org">
              <el-option v-for="item in trashtypeData" :key="item.id" :label="item.node_name" :value="item.id" @click.native="choicedrug(item)"></el-option>
            </el-select>
          </el-form-item>
          <!-- 负责人预留 -->
          <!-- 车辆类型 -->
          <el-form-item label="申请车辆类型" prop="use_type" label-width="150px">
            <el-cascader ref="tree" @change="optpidfun" v-model="dform.use_type" :options="usetypelist" clearable :props="casprops"></el-cascader>
          </el-form-item>
          <!-- 期望到达时间 -->
          <el-form-item label="期望到达时间" prop="hope_time_at" label-width="150px">
            <el-date-picker v-model="dform.hope_time_at" @change="gettime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="是否有意向调出项目" label-width="150px">
            <el-radio v-model="radio" label="1" @change="dan1">是</el-radio>
            <el-radio v-model="radio" label="2" @change="dan2">否</el-radio>
          </el-form-item>
          <!-- 意向调出项目 -->
          <el-form-item label="意向调出项目" v-show="falg">
            <el-select v-model="dform.old_org">
              <el-option v-for="items in trashtypeDatas" :key="items.id" :label="items.node_name" :value="items.id" @click.native="choicedrugs(items)"></el-option>
            </el-select>
          </el-form-item>
          <!-- 备注 -->
          <el-form-item label="调动事由" prop="remark">
            <el-input type="textarea" v-model="dform.remark"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelfun(dform)">取 消</el-button>
          <el-button type="primary" @click="submitForm('dform')">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="进程明细" :visible.sync="jinVisible" width="30%" :before-close="handleClose">
        <el-form :model="jin_info" label-width="100px">
          <el-form-item label="申请时间">
            <el-input v-model="jin_info.start_time_at" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="调出项目名称">
            <el-input v-model="jin_info.old_org_name" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="调入项目名称">
            <el-input v-model="jin_info.current_org_name" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="调配车辆类型">
            <el-input v-model="jin_info.use_type_name" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="调配车辆牌号">
            <el-input v-model="jin_info.auto_number" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="申请状态">
            <el-input v-model="jin_info.status_name" autocomplete="off" disabled></el-input>
          </el-form-item>
        </el-form>
        <el-collapse v-model="activeNames">
          <el-collapse-item v-for="(item, index) in logs" :key="index" :name="item.id" :title="item.created_at">
            <div>{{ item.remark }}</div>
          </el-collapse-item>
        </el-collapse>
        <div slot="footer" class="dialog-footer">
          <el-button @click="jinVisible = false">取 消</el-button>
          <el-button type="primary" @click="jinVisible = false">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 列表开始 -->
      <el-table :data="tableData" style="width: 70%" stripe>
        <el-table-column prop="start_time_at" label="申请日期" width="180"></el-table-column>
        <el-table-column prop="status_name" label="申请状态" width="180"></el-table-column>
        <el-table-column prop="use_type_name" label="调配车类型" width="250"></el-table-column>
        <el-table-column prop="status" label="操作" width="350">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" @click="jincheng(scope.row.id)">进程明细</el-button>
            <el-button v-if="scope.row.status == 1 || scope.row.status == 0" size="mini" type="success" @click="endche(scope.row.id)">撤回</el-button>
            <el-button v-if="scope.row.status == 2" size="mini" type="danger" @click="queren(scope.row.id)">确认车辆送达</el-button>
            <el-button v-if="scope.row.status == 0" size="mini" type="warning" @click="endshan(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
  </div>
</template>
<script>
import Fenye from '../../../components/Fenye'
import { Getxiangmu, GetUseTypeList, Gettian, Getdiaolie, Endchehui, Getjin, Getsuoxiangmu, Chehui } from '../../../api/car'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      logs: [],
      activeNames: ['1'],
      wangfeng: '18561321433',
      cao: '17854260201',
      falg: false, // 显示
      dialogVisible: false, // 对话框
      jinVisible: false, // 进程明细对话框
      radio: '',
      dform: {
        current_org: '', // 添加项目选中
        use_type: '', // 车辆类型选中
        hope_time_at: '', // 期望到达时间
        old_org: '', // 意向调出项目
        remark: ''
      },
      trashtypeData: [], // 下拉框的数据
      trashtypeDatas: [], // 下拉框的数据
      usetypelist: [], // 车辆类型数据
      casprops: { value: 'id', label: 'tname', checkStrictly: false }, // 级联选择的配置
      drules: {
        current_org: [{ required: true, message: '请选择项目', trigger: 'change' }],
        use_type: [{ required: true, message: '请选择车辆', trigger: 'change' }],
        hope_time_at: [{ required: true, message: '请选择送达时间', trigger: 'change' }],
        remark: [
          { required: true, message: '请填写调动事由', trigger: 'change' },
          { min: 2, message: '请填写调动事由', trigger: 'blur' }
        ]
      },
      auth: '',
      tableData: [], // 调度申请列表
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      chehuiid: '', // 撤回id
      jinchengid: '', // 进程id
      jin_info: {},
      queren_id: '', // 确认id
      shanchu_id: '' // 删除id
    }
  },
  mounted() {
    this.Getdiaolist()
  },
  created() {
    let caaa = localStorage.getItem('ming')
    // console.log(caaa)
    if (caaa == this.wangfeng) {
      this.auth = 'admins'
      // console.log(this.auth)
    } else if (caaa == this.cao) {
      this.auth = 'admins'
      // console.log(this.auth)
    } else {
      this.auth = 'users'
      // console.log(this.auth)
    }
  },
  methods: {
    // 删除
    endshan(e) {
      this.shanchu_id = e
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Endshanchu()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //确认送达
    queren(e) {
      this.queren_id = e
      console.log(this.queren_id);
      this.$confirm('确认车辆送达, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Querensongda()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 撤回
    endche(e) {
      console.log(e)
      this.chehuiid = e
      // this.Endchehuis()
      this.$confirm('此操作将撤回车辆申请, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Endchehuis()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消撤回'
          })
        })
    },
    // 进程明细
    jincheng(e) {
      console.log(e)
      this.jinchengid = e
      this.Getjincheng()
      this.jinVisible = true
    },
    // 添加申请
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 成功
          this.ClientInformations(this.dform) // 添加用户信息
          this.$refs[formName].resetFields()
        }
      })
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
      this.Getxiangmulist()
      this.Getsuoxiangmus()
      this.getusetypelistfun()
    },
    // 关闭添加弹窗
    handleClose() {
      this.dialogVisible = false
      this.jinVisible = false
    },
    // 选中获取项目id
    choicedrug(e) {
      this.dform.current_org = e.id
      console.log(this.dform.current_org)
    },
    choicedrugs(e) {
      this.dform.old_org = e.id
      console.log(this.dform.old_org)
    },
    // 获取车辆类型的值
    optpidfun(e) {
      this.dform.use_type = e[2] ? e[2] : 0
      console.log(this.dform.use_type)
    },
    gettime(e) {
      this.dform.hope_time_at = e
      console.log(this.dform.hope_time_at)
    },
    // 单选是
    dan1(e) {
      console.log(e)
      this.falg = true
    },
    // 单选否
    dan2(e) {
      console.log(e)
      this.falg = false
      this.dform.old_org = ''
    },
    // 添加取消
    cancelfun() {
      this.$refs.dform.resetFields()
      this.dialogVisible = false
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getdiaolist()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Getdiaolist()
    },

    //******* 请求区 ********/
    // 获取项目
    async Getxiangmulist() {
      const { data } = await Getxiangmu()
      this.trashtypeData = data.data
    },
    // 获取所有项目
    async Getsuoxiangmus() {
      const { data } = await Getsuoxiangmu()
      this.trashtypeDatas = data.data
    },
    // 车辆类型
    async getusetypelistfun() {
      const { data } = await GetUseTypeList({ page: 1, size: 10000 })
      this.usetypelist = data.data.list
    },

    // 添加车辆申请
    async ClientInformations(v) {
      const { data } = await Gettian(v)
      this.getanpostfun(data, '添加', this.Getdiaolist())
    },
    // 调度申请列表
    async Getdiaolist() {
      const { data } = await Getdiaolie({ show_type: 2, status: 2, ...this.pageData })
      this.tableData = data.data.list
      // console.log(this.tableData)
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
      this.getanpostfun(data, '获取')
    },
    // 撤回
    async Endchehuis() {
      const { data } = await Chehui({ id: this.chehuiid, status: 9 })
      this.getanpostfun(data, '撤回', this.Getdiaolist())
    },

    // 删除
    async Endshanchu() {
      const { data } = await Endchehui({ id: this.shanchu_id })
      this.getanpostfun(data, '删除', this.Getdiaolist())
    },

    // 确认送达
    async Querensongda() {
      const { data } = await Chehui({ id: this.queren_id, status: 3 })
      this.getanpostfun(data, '确认', this.Getdiaolist())
    },
    async Getjincheng() {
      const { data } = await Getjin({ id: this.jinchengid })
      console.log(data)
      this.jin_info = data.data.info
      this.logs = data.data.logs
      console.log(this.logs)
    },

    // 公共方法区└(^o^)┘└(^o^)┘
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-input.is-disabled .el-input__inner {
  background-color: aqua !important;
}
</style>
