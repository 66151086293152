<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>员工列表</el-breadcrumb-item>
      <el-breadcrumb-item>个人信息</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片试图 -->
    <el-card class="box-card">
      <table class="ly_table">
        <tbody>
          <tr>
            <th>员工编号</th>
            <td>{{ UserFrom.staff_code }}</td>
            <th>入职时间</th>
            <td>
             {{UserFrom.onboarding}}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="ly_table">
        <tbody>
          <tr>
            <th>姓名</th>
            <td>{{ UserFrom.name }}</td>
            <th>性别</th>
            <td>{{ UserFrom.gendor }}</td>
            <th>年龄</th>
            <td>{{ UserFrom.age }}</td>
            <th>出生日期</th>
            <td>{{ UserFrom.birth }}</td>

            <th rowspan="4">
              <div class="user_img">
                <img :src="UserFrom.avatar" alt="" />
              </div>
            </th>
          </tr>
          <tr>
            <th>民族</th>
            <td>{{ UserFrom.nation }}</td>
            <th>身份证号</th>
            <td>{{ UserFrom.identity }}</td>
            <th>联系方式</th>
            <td>{{ UserFrom.mobile }}</td>
            <th>政治面貌</th>
            <td>{{ UserFrom.org }}</td>
          </tr>
          <tr>
            <th>户口性质</th>
            <td>{{ UserFrom.household_type }}</td>
            <th>籍贯</th>
            <td>{{ native_place }}</td>
            <th>现住址</th>
            <td>{{ UserFrom.present_address }}</td>
            <th>婚姻状况</th>
            <td>{{ UserFrom.marriage }}</td>
          </tr>
          <tr>
            <th>入职时间</th>
            <td>{{ UserFrom.onboarding }}</td>
            <th>状态</th>
            <td>未婚</td>
            <th>转正时间</th>
            <td>{{ UserFrom.turn_on }}</td>
            <th>学历</th>
            <td>{{ UserFrom.education }}</td>
          </tr>

          <tr>
            <th>服装号码</th>
            <td>{{ UserFrom.size_designation }}</td>
            <th>鞋码</th>
            <td>{{ UserFrom.footwear_size }}</td>
            <th>驾照类型</th>
            <td>
              <span v-for="item in UserFrom.license" :key="item.index">{{ item }}</span>
            </td>
            <th>工资卡号</th>
            <td>{{ UserFrom.bank_num }}</td>
            <th><el-button type="primary" @click="addPhotograph(UserFrom.user_id)">上传证件照片</el-button></th>
          </tr>
          <!-- //上传照片 -->
          <el-dialog class="piantanchuang" title="请上传证件照片" :visible.sync="dialogVisible" width="70%" :top="diglogTop">
            <div v-for="item in chunList" :key="item.index">
              <span>添加{{ item.ming }}</span>
              <el-upload
                class="chupian"
                action=""
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handSuccess"
                :on-erroe="handerror"
                :http-request="handBefore"
                :multiple="false"
                :data="{ name: `${item.name}` }"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>

            <span slot="footer" class="dialog-footer">
              <el-button>取 消</el-button>
              <el-button type="primary" @click="upImgsOk">确 定</el-button>
            </span>
          </el-dialog>
          <tr>
            <th>薪资</th>
            <td>{{ UserFrom.salary }}</td>
            <th>薪资（试用期）</th>
            <td>{{ UserFrom.salary_trial }}</td>
            <th>岗位信息</th>
            <td>经历</td>
            <th>所属公司</th>
            <td colspan="2">{{ UserFrom.company }}</td>
          </tr>
          <tr>
            <th colspan="5">外语能力</th>

            <th>外语种类和等级</th>
            <td colspan="4">
              <span v-for="item in UserFrom.language" :key="item.index">{{ item.name }}({{ item.grade }})</span>
            </td>
          </tr>
          <tr>
            <th colspan="5">紧急联系人</th>
            <th>姓名</th>
            <td>
              <span v-for="item in UserFrom.emergencys" :key="item.index">{{ item.name }}</span>
            </td>
            <th>电话</th>

            <td>
              <span v-for="item in UserFrom.emergencys" :key="item.index">{{ item.mobile }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="ly_table">
        <tbody>
          <tr>
            <th rowspan="4">家庭情况</th>
            <th>姓名</th>
            <th>关系</th>
            <th>出生日期</th>
            <th colspan="4">工作单位/职业</th>
            <th>联系方式</th>
          </tr>
          <tr v-for="item in UserFrom.family_status" :key="item.index">
            <td>{{ item.name }}</td>
            <td>{{ item.relation }}</td>
            <td>{{ item.birthday }}</td>
            <td colspan="4">{{ item.station }}</td>
            <td>{{ item.mobile }}</td>
          </tr>
        </tbody>
      </table>
      <table class="ly_table">
        <tbody>
          <tr>
            <th rowspan="4">学历</th>
            <th colspan="3">毕业院校</th>
            <th colspan="2">起止时间</th>
            <th colspan="2">所学专业</th>
            <th>学历</th>
          </tr>
          <tr v-for="item in UserFrom.education_background" :key="item.index">
            <td colspan="3">{{ item.school }}</td>
            <td colspan="2">{{ item.date }}</td>
            <td colspan="2">{{ item.major }}</td>
            <td>{{ item.education }}</td>
          </tr>
        </tbody>
      </table>
      <table class="ly_table">
        <tbody>
          <tr>
            <th rowspan="4">工作经历</th>
            <th colspan="3">单位名称</th>
            <th colspan="2">起止时间</th>
            <th>职位</th>
            <th>薪资待遇</th>
            <th>离职原因</th>
          </tr>
          <tr v-for="item in UserFrom.education_background" :key="item.index">
            <td colspan="3">{{ item.date }}</td>
            <td colspan="2">{{ item.company }}</td>
            <td colspan="2">{{ item.position }}</td>
            <td>{{ item.remuneration }}</td>
          </tr>
        </tbody>
      </table>
      <table class="ly_table">
        <tbody>
          <tr>
            <th>证书或培训</th>
            <td>
              <span>{{ UserFrom.education_experience }}</span>
            </td>
            <th>获奖经历</th>
            <td>
              <span>{{ UserFrom.award }}</span>
            </td>
          </tr>
          <tr>
            <th>个人特长</th>
            <td colspan="8">
              <span>{{ UserFrom.personage }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- 岗位信息 -->
      <div class='contract_int'>
        <h3>合同信息</h3>
        <el-button type="primary" @click='contracVisible = true'>添加合同信息</el-button>
      </div>
<!--添加合同对话框-->
      <el-dialog
        title="添加合同"
        :visible.sync="contracVisible"
        width="30%"
        >
        <el-form :rules='rules' ref="contractFromRef" :model="contractFrom" label-width="80px">
          <el-form-item label="签订时间" prop='sign_date'>
            <el-date-picker  v-model="contractFrom.sign_date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" name="created_at"></el-date-picker>
          </el-form-item>
          <el-form-item label="生效日期" prop='sign_date'>
            <el-date-picker v-model="contractFrom.begin_date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" name="created_at"></el-date-picker>
          </el-form-item>
          <el-form-item label="到期时间" prop='sign_date'>
            <el-date-picker v-model="contractFrom.end_date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" name="created_at"></el-date-picker>
          </el-form-item>
          <el-form-item label="签订原因" prop='reason'>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="contractFrom.reason">
            </el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
    <el-button @click="contracVisible = false">取 消</el-button>
    <el-button type="primary" @click="addContrancInt()">确 定</el-button>
  </span>
      </el-dialog>
      <el-table
        :data="UserFrom.contract"
        border
        stripe
        style="width: 100%"
        max-height="300">
        <el-table-column
          fixed
          sortable
          prop="id"
          label="序号"
          width="180">

        </el-table-column>
        <el-table-column
          sortable
          prop="sign_date"
          label="签订时间"
          width="180">
        </el-table-column>
        <el-table-column
          sortable
          prop="begin_date"
          label="开始时间"
          width="180">
        </el-table-column>
        <el-table-column
          sortable
          prop="end_date"
          label="到期时间">
        </el-table-column>
        <el-table-column

          prop="reason"
          label="签订原因">
        </el-table-column>
<!--        <el-table-column-->

<!--          prop="remark"-->
<!--          label="操作">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button-->
<!--              size="mini"-->
<!--              type="warning"-->
<!--              @click="handleEdit(scope.$index, scope.row)">编辑</el-button>-->

<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>

<!--         福利信息 -->
        <div class='contract_int'>
          <h3>福利信息</h3>
          <el-button type="primary" @click='welfareVisible = true'>添加福利信息</el-button>
        </div>
        <!--添加福利对话框-->
        <el-dialog
          title="添加福利"
          :visible.sync="welfareVisible"
          width="40%"
        >
          <el-form :rules='rules' ref="welfareFromRef" :model="welfareFrom" label-width="80px">
            <el-form-item label="项目" prop='item'>
              <el-input v-model="welfareFrom.item" placeholder="请输入内容"></el-input>

            </el-form-item>
            <el-form-item label="内容" prop='content'>
              <el-input v-model="welfareFrom.content" placeholder="请输入内容"></el-input>

            </el-form-item>
            <el-row>
            <el-form-item  label="金额" prop='cost'>
              <el-col :span="22">
              <el-input v-model="welfareFrom.cost" placeholder="请输入金额"></el-input>
              </el-col>
                <el-col :span="1">
                <span style='margin-left: 10px'>{{fen}}</span>
                </el-col>

            </el-form-item>
            </el-row>
            <el-form-item label="原因" prop='reason'>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="welfareFrom.reason">
              </el-input>
            </el-form-item>
          </el-form>

          <span slot="footer" class="dialog-footer">
    <el-button @click="welfareVisible = false">取 消</el-button>
    <el-button type="primary" @click="addWelfareInt('add')">确 定</el-button>
  </span>
        </el-dialog>
<!--      修改-->
      <el-dialog
        title="修改福利"
        :visible.sync="editwelfareVisible"
        width="40%"
      >
        <el-form :rules='rules' ref="welfareFromRef" :model="welfareFrom" label-width="80px">
          <el-form-item label="项目" prop='item'>
            <el-input v-model="welfareFrom.item" placeholder="请输入内容"></el-input>

          </el-form-item>
          <el-form-item label="内容" prop='content'>
            <el-input v-model="welfareFrom.content" placeholder="请输入内容"></el-input>

          </el-form-item>
          <el-row>
            <el-form-item  label="金额" prop='cost'>
              <el-col :span="22">
                <el-input v-model="welfareFrom.cost" placeholder="请输入金额"></el-input>
              </el-col>
              <el-col :span="1">
                <span style='margin-left: 10px'>{{fen}}</span>
              </el-col>

            </el-form-item>
          </el-row>
          <el-form-item label="原因" prop='reason'>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="welfareFrom.reason">
            </el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
    <el-button @click="welfareVisible = false">取 消</el-button>
    <el-button type="primary" @click="editWelfarapp('edit')">确 定</el-button>
  </span>
      </el-dialog>
<!--福利列表-->

      <el-table
        :data="UserFrom.welfare"
        border
        stripe
        style="width: 100%"
        max-height="300">
        <el-table-column
          fixed
          sortable
          prop="id"
          label="序号"
          width="180">

        </el-table-column>
        <el-table-column
          sortable
          prop="item"
          label="福利项"
          width="180">
        </el-table-column>
        <el-table-column
          sortable
          prop="content"
          label="福利内容"
          width="180">
        </el-table-column>

        <el-table-column

          prop="reason"
          label="福利原因">

        </el-table-column>
        <el-table-column

          prop="cost"
          label="福利金额(元)">

        </el-table-column>
                <el-table-column

                  prop="remark"
                  label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="editWelfareInt(scope.$index, scope.row)">编辑</el-button>
                    <!-- 删除按钮 -->
                    <el-button size="mini" type="danger" icon="el-icon-delete" @click="remoueWelfareInt(scope.row.id)"></el-button>
                  </template>
                </el-table-column>
      </el-table>

    </el-card>
  </div>
</template>
<script>
import { InfoGet } from '@/api/register'
import { getLocal } from '@/utils/local'
import { AddImg, SaveIn, AddContract, AddWelfare } from '@/api/personnel'
// 验证
import { rules } from '@/utils/rules'
// 籍贯
import { CodeToText } from 'element-china-area-data'
import { exchangefen, exchangeyuan } from '@/utils/calculator'

export default {
  data() {
    return {
      // id
      user_id: '',
      // 金额单位
      fen: '元',
      // 验证规则
      rules,
      // 用户详情
      UserFrom: [],
      // 人事可操作项
      jurisdiction: true,
      // 籍贯
      native_place: '',
      // 上传图片对话框显示
      dialogVisible: false,
      // 图片地址
      uplodaurl: '',
      // 图片上传组件的headers请求头对象
      headersObj: {
        token: getLocal('token')
      },
      // 图片显示隐藏
      dialogVisibleImg: false,
      // 展示图片地址
      dialogImageUrl: '',
      // 上传图片列表
      // imgData: [],
      imgsList: {
        avatar: '', // 头像
        id_positive: '', // 正面
        id_negative: '', // 反面
        graduate: '', // 毕业证
        degree: '' // 学位证
      },
      // 步骤
      active: 0,
      chunList: [
        { ming: '头像', name: 'avatar' }, // 头像
        { ming: '身份证正面', name: 'id_positive' }, // 正面
        { ming: '身份证反面', name: 'id_negative' }, // 反面
        { ming: '毕业证', name: 'graduate' }, // 毕业证
        { ming: '学位证', name: 'degree' } // 学位证
      ],
      // 高度
      diglogTop: '4vh',
      // 合同信息
      tableData: [
      ],
      // 控制添加合同的对话框展示隐藏
      contracVisible: false,
      // 添加合同信息
      contractFrom: {
        user: '',
        reason: '', // 签订原因
        begin_date: '', // 开始时间
        end_date: '', // 结束时间
        sign_date: ''// 签订时间
      },
      // 福利信息的数据
      // 控制添加福利的对话框展示隐藏
      welfareVisible: false,
      // 添加福利信息
      welfareFrom: {
        user: '', // 用户
        item: '', // 项目
        content: '', // 内容
        cost: 0, // 金额
        reason: '', // 原因
        method: '', // 操作
        id: ''
      },
      // 控制修改福利的对话框展示隐藏
      editwelfareVisible: false

    }
  },
  created() {
    this.getUserData()
  },
  methods: {
    // 获取个人信息
    async getUserData() {
      const { userid } = this.$route.params
      console.log(userid)
      const { data: res } = await InfoGet({user:userid})

      console.log(res,'sssssssssssssssssssssss')
      if (res.code !== 200) {
        this.$message.error('获取个人信息失败')
        return this.$router.push({
          path: 'personnel-list'
        })
      }
      this.UserFrom = res.data
      // 存储id
      this.user_id = this.UserFrom.user_id
      const jj = this.UserFrom.native_place
      // console.log(jj)
      for (let index = 0; index < 2; index++) {
        this.native_place = CodeToText[jj[0]] + '/' + CodeToText[jj[1]]
      }
      const welfareitem = this.UserFrom.welfare.map(item => {
        // eslint-disable-next-line no-return-assign
        item.cost = exchangeyuan(item.cost)
        return item
      })
      this.UserFrom.welfare = welfareitem
    },
    // 添加图片按钮
    addPhotograph(id) {
      this.dialogVisible = true
    },

    // 上传之前
    async handBefore(porm) {
      const { name } = porm.data
      console.log(porm)
      console.log(name)
      const fd = new FormData()
      fd.append('file', porm.file)
      const { data } = await AddImg(fd)
      if (data.code !== 200) {
        return this.$message.error('上传失败')
      }
      this.imgsList[name] = data.data.path
      console.log(this.imgsList)
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    // 文件上传成功时的钩子
    handSuccess(r, l) {},
    // 文件上传失败时的钩子
    handerror(err) {
      console.log(err)
    },
    // 确定上传
    async upImgsOk() {
      const { data } = await SaveIn(this.imgsList)
      console.log(data)
    },
    // 修改上传
    inputBlur(event) {
      const { name, value } = event

      console.log(name, value)
    },
    // 合同管理的操作
    // 添加合同信息
    addContrancInt() {
      this.contractFrom.user = this.UserFrom.user_id
      this.$refs.contractFromRef.validate(async valid => {
        if (valid) {
          const { data: { code }} = await AddContract(this.contractFrom)
          console.log(code)
          if (code !== 200) return this.$message.error('添加失败')
          this.$message.success('添加合同成功')
          this.$refs.contractFromRef.resetFields()

          this.contracVisible = false
        }
      })
    },
    // 修改合同
    handleEdit(id, name) {
      console.log(id, name)
    },
    // 福利操作
    async conWelfare(con) {
      this.welfareFrom.user = this.UserFrom.user_id
      this.welfareFrom.method = con
      this.welfareFrom.cost = exchangefen(this.welfareFrom.cost)
      this.fen = '分'
      const { data: { code }} = await AddWelfare(this.welfareFrom)
      if (code !== 200) return this.$message.error('操作失败')
    },
    // 添加福利修改福利删除
    addWelfareInt(fun) {
      this.$refs.welfareFromRef.validate(valid => {
        if (valid) {
          this.conWelfare(fun)
          this.$message.success('添加福利成功')
          this.$refs.welfareFromRef.resetFields()
          this.welfareVisible = false
        }
      })
    },

    // 修改
    editWelfareInt(index, row) {
      this.welfareFrom = row
      this.editwelfareVisible = true
      console.log(this.welfareFrom)
    },
    // 确定修改
    editWelfarapp(fun) {
      this.$refs.welfareFromRef.validate(valid => {
        if (valid) {
          this.conWelfare(fun)
          this.$message.success('修改福利成功')
          this.$refs.welfareFromRef.resetFields()
          this.editwelfareVisible = false
        }
      })
    },
    // 删除
    async remoueWelfareInt(id) {
      this.welfareFrom.id = id
      // 根据id删除内容

      const confirM = await this.$confirm('此操作将永久删除该福利, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // .then(() => {
      //   this.$message({
      //     type: 'success',
      //     message: '删除成功'
      //   })
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消删除'
      //   })
      // })
      // 如果用户确认了删除，则返回值为字符串confirm
      // 如果用户取消了删除，则返回值为字符串cancle
      console.log(confirM)
      if (confirM !== 'confirm') return this.$message.info('已取消删除')

      this.conWelfare('del')

      this.$message.success('已删除')
      this.getUserData()
    }
  }
}
</script>
<style lang="less" scoped>
#app .box-card {
  overflow: scroll;
  height: 83vh;
}

.ly_table {
  width: 100%;
  border: 1px solid #a5cff4;
  border-collapse: collapse;
  font-size: 12px;
  th,
  td,
  .header {
    height: 24px !important;
    height: 20px;
    border: 1px solid #d0e4f3;
    padding: 3px 8px 0;
  }
}
th {
  background: #f7fcff;
  font-weight: normal;
  color: #000;
}
.user_img {
  width: 136px;
  height: 170px;
  text-align: center;
  margin: 0 auto;
  img {
    width: 100%;
  }
}
h3 {
  margin: 0;
}
.textarea > .el-input__inner {
  border: 0;
}
.piantanchuang {
  .chupian {
    .el-upload-list {
      .el-upload-list__item {
        width: 80px;
        height: 80px;
      }
    }
    .el-upload--picture-card {
      width: 80px;
      height: 80px;
      line-height: 80px;
    }
  }
}
.contract_int{
 display: flex;
  justify-content: space-between;
  padding: 10px;

}
</style>
