import axios from './index'

// 车牌ヾ(*´▽‘*)ﾉ Numbe
export const AddNumbe = data => {
  return axios.request({
    url: '/auto/numbe/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetNumbeList = params => {
  return axios.request({
    url: '/auto/numbe/list',
    method: 'get',
    params
  })
}
// 修改车牌
export const EditNumbe = data => {
  return axios.request({
    url: '/auto/numbe/edit',
    method: 'post',
    data
  })
}
// 删除车牌
export const DelNumbe = params => {
  return axios.request({
    url: '/auto/numbe/del',
    method: 'get',
    params
  })
}
// ❤❤❤❤❤❤结束

// 车牌ヾ(*´▽‘*)ﾉ Ownership
export const AddOwnership = data => {
  return axios.request({
    url: '/auto/ownership/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetOwnershipList = params => {
  return axios.request({
    url: '/auto/ownership/list',
    method: 'get',
    params
  })
}
// 修改车牌
export const EditOwnership = data => {
  return axios.request({
    url: '/auto/ownership/edit',
    method: 'post',
    data
  })
}
// 删除车牌
export const DelOwnership = params => {
  return axios.request({
    url: '/auto/ownership/del',
    method: 'get',
    params
  })
}
// ❤❤❤❤❤❤结束
// 使用类型ヾ(*´▽‘*)ﾉ UseType
export const AddUseType = data => {
  return axios.request({
    url: '/auto/use_type/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetUseTypeList = params => {
  return axios.request({
    url: '/auto/use_types',
    method: 'post',
    params
  })
}
// 修改使用类型
export const EditUseType = data => {
  return axios.request({
    url: '/auto/use_type/edit',
    method: 'post',
    data
  })
}
// 删除使用类型
export const DelUseType = params => {
  return axios.request({
    url: '/auto/use_type/del',
    method: 'get',
    params
  })
}
// ❤❤❤❤❤❤结束
// 保险公司ヾ(*´▽‘*)ﾉ company
export const AddCompany = data => {
  return axios.request({
    url: '/auto_insurance/company/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetCompanyList = params => {
  return axios.request({
    url: '/auto_insurance/companies',
    method: 'post',
    params
  })
}
// 修改保险公司
export const EditCompany = data => {
  return axios.request({
    url: '/auto_insurance/company/edit',
    method: 'post',
    data
  })
}
// 删除保险公司
export const DelCompany = params => {
  return axios.request({
    url: '/auto_insurance/company/del',
    method: 'get',
    params
  })
}
// ❤❤❤❤❤❤结束
// 保险类型ヾ(*´▽‘*)ﾉ Insurancetype
export const AddInsurancetype = data => {
  return axios.request({
    url: '/auto_insurance/type/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetInsurancetypeList = params => {
  return axios.request({
    url: '/auto_insurance/types',
    method: 'post',
    params
  })
}
// 修改保险类型
export const EditInsurancetype = data => {
  return axios.request({
    url: '/auto_insurance/type/edit',
    method: 'post',
    data
  })
}
// 删除保险类型
export const DelInsurancetype = params => {
  return axios.request({
    url: '/auto_insurance/type/del',
    method: 'get',
    params
  })
}
// 获取品牌上门
export const Getproducerlist = data => {
  return axios.request({
    url: '/auto/producer/list',
    method: 'post',
    data
  })
}
// 车辆信息ヾ(*´▽‘*)ﾉ Insurancetype
export const Addauto = data => {
  return axios.request({
    url: '/auto/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetautoList = params => {
  return axios.request({
    url: '/auto/list',
    method: 'post',
    params
  })
}
// 修改车辆
export const EditAuto = data => {
  return axios.request({
    url: '/auto/edit',
    method: 'post',
    data
  })
}
// // 删除车辆信息
export const Delauto = params => {
  return axios.request({
    url: '/auto/del',
    method: 'get',
    params
  })
}
//车辆租赁
// export const GetrentList = data => {
//   return axios.request({
//     url: '/auto/lease/add',
//     method: 'post',
//     data
//   })
// }
//绑定车辆租赁列表
export const GetLeaseList = data => {
  return axios.request({
    url: '/auto/lease/list',
    method: 'post',
    data
  })
}
// 添加租赁车辆
export const AddRentIns = data => {
  return axios.request({
    url: '/auto/lease/add',
    method: 'post',
    data
  })
}
// 删除绑定租赁车辆
export const DelRentIns = params => {
  return axios.request({
    url: '/auto/lease/del',
    method: 'get',
    params
  })
}
// 修改绑定租赁车辆
export const EditRentIns = data => {
  return axios.request({
    url: '/auto/lease/edit',
    method: 'post',
    data
  })
}
// ❤❤❤❤❤❤结束
// 绑定保险ヾ(*´▽‘*)ﾉ company
export const AddAutoIns = data => {
  return axios.request({
    url: '/auto_insurance/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetAutoInsList = params => {
  return axios.request({
    url: '/auto_insurance/list',
    method: 'post',
    params
  })
}
// 修改绑定保险
export const EditAutoIns = data => {
  return axios.request({
    url: '/auto_insurance/edit',
    method: 'post',
    data
  })
}
// 删除绑定保险
export const DelAutoIns = params => {
  return axios.request({
    url: '/auto_insurance/del',
    method: 'get',
    params
  })
}
// ❤❤❤❤❤❤结束
// 绑定加油记录ヾ(*´▽‘*)ﾉ company
export const AddRefueling = data => {
  return axios.request({
    url: '/auto_oil/refueling/add',
    method: 'post',
    data
  })
}
// 获取列表
export const GetRefuelingList = data => {
  return axios.request({
    url: '/auto_oil/refueling/list',
    method: 'post',
    data
  })
}
// 修改加油记录
// export const EditRefueling = data => {
//   return axios.request({
//     url: '/auto_oil/refueling/edit',
//     method: 'post',
//     data
//   })
// }
//
// 删除加油记录
export const DelRefueling = params => {
  return axios.request({
    url: '/auto_oil/refueling/del',
    method: 'get',
    params
  })
}
// ❤❤❤❤❤❤结束
// 获取预警
export const GetYujingt = params => {
  return axios.request({
    url: '/admin/index/expiration_reminder',
    method: 'get',
    params
  })
}
// 获取代办
export const GetDaiban = params => {
  return axios.request({
    url: '/admin/index/agency_matters',
    method: 'get',
    params
  })
}

// 项目列表
export const Getxiangmu = params => {
  return axios.request({
    url: '/auto/user_associats',
    method: 'get',
    params
  })
}
// 所有项目列表
export const Getsuoxiangmu = params => {
  return axios.request({
    url: '/auto/all_associats',
    method: 'get',
    params
  })
}

// 加油审核通过列表
export const Gettong = data => {
  return axios.request({
    url: `/auto_oil/refueling/audit`,
    method: 'post',
    data
  })
}

// 加油审核删除

export const Endshanchu = params => {
  return axios.request({
    url: '/auto_oil/refueling/del',
    method: 'get',
    params
  })
}

// 加油审核信息编辑

export const Endbianji = data => {
  return axios.request({
    url: `/auto_oil/refueling/edit`,
    method: 'post',
    data
  })
}
// 拉取加油当条的信息
export const Getdang = params => {
  return axios.request({
    url: '/auto_oil/refueling/one',
    method: 'get',
    params
  })
}


// 用油分析

export const Getyonglist = data => {
  return axios.request({
    url: `/auto_oil/refueling/auto_refueling_analysis`,
    method: 'post',
    data
  })
}

// 调度添加申请
export const Gettian = data => {
  return axios.request({
    url: `/auto/deploy/add`,
    method: 'post',
    data
  })
}

// 调度申请列表 

export const Getdiaolie = data => {
  return axios.request({
    url: `/auto/deploy/list`,
    method: 'post',
    data
  })
}

// 调度申请删除
export const Endchehui = params => {
  return axios.request({
    url: '/auto/deploy/del',
    method: 'get',
    params
  })
}
// 调度进程明细
export const Getjin = params => {
  return axios.request({
    url: '/auto/deploy/info',
    method: 'get',
    params
  })
}

// 管理员审核同意 
export const Guanliyuan = data => {
  return axios.request({
    url: `/auto/deploy/admin_deploy`,
    method: 'post',
    data
  })
}
// 管理员审核同意 
export const Xiangmushen = data => {
  return axios.request({
    url: `/auto/deploy/pro_deploy`,
    method: 'post',
    data
  })
}
// 撤回
export const Chehui = data => {
  return axios.request({
    url: `/auto/deploy/update_status`,
    method: 'post',
    data
  })
}
// 添加车辆维修单位
export const Settian = data => {
  return axios.request({
    url: `/auto_repair_company/add`,
    method: 'post',
    data
  })
}
// 车辆维修单位列表
export const Getlist = data => {
  return axios.request({
    url: `/auto_repair_company/list`,
    method: 'post',
    data
  })
}
// 车辆维修单位修改
export const Setlist = data => {
  return axios.request({
    url: `/auto_repair_company/update`,
    method: 'post',
    data
  })
}
// 车辆维修单位删除
export const Endlist = params => {
  return axios.request({
    url: `/auto_repair_company/del`,
    method: 'get',
    params
  })
}

// 车辆维修信息添加  /auto_maintenance/add
export const Setwei = data => {
  return axios.request({
    url: `/auto_maintenance/add`,
    method: 'post',
    data
  })
}
// 车辆维修信息列表
export const Getweilist = data => {
  return axios.request({
    url: `/auto_maintenance/list`,
    method: 'post',
    data
  })
}
// 车辆维修信息列表
export const Endwei = data => {
  return axios.request({
    url: `/auto_maintenance/edit`,
    method: 'post',
    data
  })
}

// 拉取车辆维修信息单条 
export const Getdantiao = params => {
  return axios.request({
    url: `/auto_maintenance/info`,
    method: 'get',
    params
  })
}
// 删除车辆维修信息单条 
export const Shandan = params => {
  return axios.request({
    url: `/auto_maintenance/del`,
    method: 'get',
    params
  })
}

// 出险添加
export const addLosses = data => {
  return axios.request({
    url: `/auto_insurance/losses/add`,
    method: 'post',
    data
  })
}
// 出险列表
export const getLosses = data => {
  return axios.request({
    url: `/auto_insurance/losses/list`,
    method: 'post',
    data
  })
}
// 出险详情
export const infoLosses = params => {
  return axios.request({
    url: `/auto_insurance/losses/info`,
    method: 'get',
    params
  })
}
// 出险修改
export const editLosses = data => {
  return axios.request({
    url: `/auto_insurance/losses/edit`,
    method: 'post',
    data
  })
}
// 出险删除
export const delLosses = params => {
  return axios.request({
    url: `/auto_insurance/losses/del`,
    method: 'get',
    params
  })
}

// 车辆所有权
export const Ownerships = data => {
  return axios.request({
    url: '/auto/ownerships',
    method: 'post',
    data
  })
}