import axios from './index'

// 列表菜单树
export const Cailist = data => {
    return axios.request({
      url: '/user/menu/list',
      method: 'post',
      data,
    })
  }

  // 权限管理列表
export const Xinlieshu = data => {
    return axios.request({
      url: '/user/menu/create',
      method: 'post',
      data,
    })
  }
  // 权限单条信息
export const Onetiao = data => {
    return axios.request({
      url: '/user/menu/info',
      method: 'post',
      data,
    })
  }
  // 删除权限单条
export const Shandan = data => {
    return axios.request({
      url: '/user/menu/del',
      method: 'post',
      data,
    })
  }
  // 编辑权限单条
export const Endxius = data => {
    return axios.request({
      url: '/user/menu/edit',
      method: 'post',
      data,
    })
  }