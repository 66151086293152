<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>

      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-button type="primary" @click="addRolesName()">添加角色</el-button>
    </el-card>
    <!-- 表格 -->
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index"></el-table-column>
      <el-table-column label="角色名称" prop="name"></el-table-column>
      <el-table-column label="角色描述" prop="roleDesc"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" type="primary" @click="finRoles(scope.row.id)">编辑</el-button>
          <el-button icon="el-icon-delete" type="danger" @click="deleRolesUser(scope.row.id)">删除</el-button>
          <el-button icon="el-icon-s-tools" type="warning" @click="showSetRighDialog(scope.row)">分配权限</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加角色对话框 -->
    <el-dialog title="添加角色" :visible.sync="dialogVisible" width="50%" @close="addRolesClose">

      <el-form :model="addeForm" :rules="rolesRules" ref="addeFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="addeForm.name"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="roleDesc">
          <el-input v-model="addeForm.roleDesc"></el-input>
        </el-form-item>
        <el-form-item label="角色上级">
          <el-cascader
            v-model="addeForm.parent_id"
            :options="rightoptions"
            :props="optionProps"
            :show-all-levels="false"

            @change="handleChange"></el-cascader>
          <span>不选择为顶级角色</span>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addRoles">确 定</el-button>
  </span>
    </el-dialog>
    <!-- 修改角色对话框 -->
    <el-dialog title="修改角色" :visible.sync="editlogVisible" width="50%" @close="addRolesClose">

      <el-form :model="editRolesForm" :rules="rolesRules" ref="addeFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="editRolesForm.name"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="roleDesc">
          <el-input v-model="editRolesForm.roleDesc"></el-input>
        </el-form-item>
        <el-form-item label="角色上级">
          <el-cascader
            v-model="editRolesForm.parent_id"
            :options="rightoptions"
            :props="optionProps"
            :show-all-levels="false"

            @change="eidthandleChange()"></el-cascader>
          <span>不选择为顶级角色</span>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="editRoles">确 定</el-button>
  </span>
    </el-dialog>
    <!-- 分配权限对话框 -->
    <el-dialog
      title="分配权限"
      :visible.sync="setRighDialogVisible"
      width="50%"
      @close="setRightDialogClosed">
<!--      树形控件-->
      <el-tree
ref='terrRef'
:data="rightlist"
:props="treeProps"
show-checkbox
node-key='id'
:default-checked-keys='defKeys'
               default-expand-all
               >

      </el-tree>
      <span slot="footer" class="dialog-footer">
    <el-button @click="setRighDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="allotRights">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { GetPermission, GetParent, GetRoleone, GetRoleDel, RoleAdd, RoleEdit, AddDispatch } from '@/api/personnel'
import { rolesRules } from '@/utils/roleRules'
// eslint-disable-next-line no-duplicate-imports
import { GetLimits } from '@/api/personnel'

export default {
  data() {
    return {
      // 添加角色数据
      addeForm: {
        name: '', // 角色名称
        roleDesc: '', // 角色描述
        parent_id: 0 // 上级id
      },
      // 验证规则
      rolesRules,
      // 获取角色列表数据
      rightoptions: [],
      // 修改展示内容
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'childs',
        checkStrictly: true
      },
      // 初始显示
      dialogVisible: false,
      // 修改的数据
      editRolesForm: {},
      // 修改窗口
      editlogVisible: false,
      // 获取权限列表数据
      rightlist: [],
      // 树形控件属性绑定
      treeProps: {
        label: 'name',
        children: 'childs'

      },
      // 控制分配权限对话框
      setRighDialogVisible: false,
      // 默认选中得节点
      defKeys: [],
      // 即将分配角色的id
      role: {
        id: '',
        permissions: []
      },
      tableData: [

      ],
      // 修改是显示的层级
      parenteidt: []
    }
  },
  created() {
    this.getParent()
  },
  methods: {
    // 获取角色列表
    async getParent() {
      // 发起请求
      const { data } = await GetParent()
      // 调用弹窗提示
      this.tishi(data.code, '获取角色列表')
      // 给添加修改的级联选择器提供数据
      this.rightoptions = data.data
      // 调用递归给列表添加数据
      this.setdata(this.rightoptions)
      console.log(this.tableData)
    },
    // 递归获取的表push到一个数组中
    setdata(datas) {
      // forech循环数组
      datas.forEach((v) => {
        // 把每一项添加到列表数组中
        this.tableData.push(v)

        // 判断是否有子项
        if (v.childs && v.childs.length > 0) {
          // 如果有递归
          this.setdata(v.childs)
        } else {
          // 如果没有吧子项设置undefined，便于级联选择器使用
          v.childs = undefined
        }
      })
    },
    // 监听添加修改关闭窗口重置内容
    addRolesClose() {
      this.$refs.addeFormRef.resetFields()
    },
    // 添加角色按钮
    addRolesName() {
      this.dialogVisible = true
    },
    // 级联选择器选中节点
    handleChange(num) {
      // 调用转换方法
      this.transitionString(num, 'addeForm')
    },
    // 修改时级联选中节点
    eidthandleChange(num) {
      // 调用转换方法
      this.transitionString(num, 'editRolesForm')
    },
    // 选中节点转换成字符串
    transitionString(valeu, name) {
      // value是级联选择的值name是要修改的对象名
      valeu.forEach(item => {
        this[name].parent_id = item
      })

      // console.log(num)
    },
    // 添加请求
    async addRoles() {
      // 前置验证
      this.$refs.addeFormRef.validate(async valid => {
        if (!valid) return false
        // 发起请求
        const { data } = await RoleAdd(this.addeForm)
        // 调用提示
        this.tishi(data.code, '添加角色')
        // 清空
        this.$refs.addeFormRef.resetFields()
        // 关闭
        this.dialogVisible = false
      })

      // this.getParent()
    },
    // 删除的方法
    async deleRolesUser(id) {
      const deliRight = await this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (deliRight !== 'confirm') return this.$message.info('已取消删除')
      const { data } = await GetRoleDel(id)
      console.log(data)
      this.tishi(data.code, '删除角色')
    },

    // 点击修改数据
    async finRoles(rightid) {
      const { data } = await GetRoleone(rightid)
      console.log(data)
      this.editRolesForm = data.data
      this.editRolesForm.parent_id = JSON.parse(data.data.parent_id)
      this.editlogVisible = true
      console.log(this.editRolesForm)
    },
    // 确认编辑
    editRoles() {
      this.$refs.addeFormRef.validate(async valid => {
        if (!valid) return false
        const { data } = await RoleEdit(this.editRolesForm)
        this.tishi(data.code, '修改角色')

        this.$refs.addeFormRef.resetFields()
        this.editlogVisible = false
        // 获取列表
      })
    },

    // 获取权限列表
    async getQu() {
      const { data } = await GetPermission()
      console.log(data)
    },

    // 删除选中的权限
    async removeRightById(role, rightId) {
      // console.log(roleId, rightId)
      // 弹框提示用户是否要删除
      const confiRight = await this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confiRight !== 'confirm') {
        return this.$message.info('已取消删除')
      }
    },
    // 分配权限
    async showSetRighDialog(role) {
      // 把用户id添加
      this.role.id = role.id
      // 发起请求
      const { data } = await GetLimits()
      if (data.code !== 200) return this.$message.error('获取权限列表失败')
      // 获取权限列表数据保存
      this.rightlist = data.data
      console.log(this.rightlist)
      // 获取已有的权限数据
      console.log('------------------')
      const { data: res } = await GetRoleone(role.id)
      this.editRolesForm = res.data
      console.log(this.editRolesForm)
      this.defKeys = res.data.permissions.map(item => {
        return item.id
      })
      this.setRighDialogVisible = true
    },

    // 点击确定按钮
    async allotRights() {
      // 获取
      // 选中的权限
      this.role.permissions = [...this.$refs.terrRef.getCheckedKeys()]
      // 发起请求
      console.log(this.role)
      const { data } = await AddDispatch(this.role)
      console.log(data)
      this.tishi(data.code, '分配权限')
      // 权限列表清空
      this.role.permissions = []
      this.setRighDialogVisible = false
    },
    // 关闭分配权限对话框
    setRightDialogClosed() {
      this.defKeys = []
    },
    // 提示
    tishi(code, text) {
      if (code === 200) {
        this.$message.success(text + '成功')
        // 判断是不是获取角色列表
        if (text !== '获取角色列表') {
          // 获取列表
          this.golist()
        }
      } else {
        this.$message.error(text + '失败')
        return false
      }
    },
    // 添加修改删除的集成获取列表
    golist() {
      this.tableData = []
      this.getParent()
    }
  }
}
</script>
<style lang="less" scoped>
.el-button {
  padding: 8px 11px !important;
}
.el-tag {
  margin: 7px;
}
.bdtop {
  border-top: 1px solid #eee;
}
.bbottom {
  border-bottom: 1px solid #eee;
}
.vcenter {
  display: flex;
  align-items: center;
}
</style>
