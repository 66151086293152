// export const baseURL = 'http://office.local/api/user'
// export const baseURL = 'http://dev.console.haiwojiamei.com/api'
// export const baseURL = '/api'
// 本地开发环境
const devBaseUrl = '/api'
// 线上测试环境
const proBaseUrl = 'https://console.haiwojiamei.com/api'
// const proBaseUrl = 'https://dev.console.haiwojiamei.com/api'
const secBaseUrl = 'https://api.haiwojiamei.com/api'
// const secBaseUrl = 'https://dev.api.haiwojiamei.com/api'
let url
process.env.NODE_ENV === 'development' ? url = devBaseUrl : url = proBaseUrl
export const baseURL = proBaseUrl
export const secbaseURL = secBaseUrl
