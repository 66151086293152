import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
// 倒入全局样式表
import './assets/css/global.css'
// 倒入字体
import './assets/css/font/iconfont.css'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import ZmTreeOrg from 'zm-tree-org';
import "zm-tree-org/lib/zm-tree-org.css";

Vue.use(ZmTreeOrg);
// 引入复制
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import { Loading } from 'element-ui';

Vue.use(Loading.directive);

// 引入非对称加密
// import { JSEncrypt } from 'jsencrypt'
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
