import axios from './index'
// 注册
export const Register = data => {
  return axios.request({
    url: '/user/register',
    method: 'post',
    data
  })
}
// 添加个人信息
export const InformationAp = data => {
  return axios.request({
    url: '/user/info/save',
    method: 'post',
    data
  })
}
// 获取姓名电话(用户详细信息用户列表通过id查询)
export const InfoGet = params => {
  return axios.request({
    url: '/user/info/get',
    method: 'get',
    params
  })
}
// 获取银行信息
export const GetBank = data => {
  return axios.request({
    url: '/system/banks',
    method: 'get'
  })
}
