<template>
  <div id="app">

      <router-view />

  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style lang="less">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.el-input.is-disabled .el-input__inner{
  background-color: #FFFF !important;
  color: #606266 !important;
  width: 250px !important;
}
// 表格宽度自适应 支持响应式
.el-table{
  width: 100%;
  .el-table__header-wrapper table,.el-table__body-wrapper table{
    width: 100% !important;
  }
  .el-table__body, .el-table__footer, .el-table__header{
    table-layout: auto;
  }
}
.el-radio__input.is-disabled+span.el-radio__label:hover{
 cursor:auto !important;
}
.el-select .el-input.is-disabled .el-input__inner{
cursor: auto !important;
}

</style>
