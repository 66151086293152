<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像 -->
      <div class="avatar_box">
        <img src="@/assets/logo.jpg" alt="" />
      </div>
      <p class="pen">海沃嘉美·智慧有方数字化系统</p>
      <!-- 表单 -->
      <el-form :model="loginFrom" :rules="loginRules" ref="loginFprmRef" label-width="0px" class="login_from">
        <!-- 用户名 -->
        <el-form-item prop="mobile">
          <el-input placeholder="请输入手机号" v-model="loginFrom.mobile" prefix-icon="iconfont icon-user"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input placeholder="请输入密码" type="password" v-model="loginFrom.password" prefix-icon="iconfont icon-3702mima"></el-input>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button @click="logins" type="primary">登录</el-button>
          <!-- <el-button @click="resetLoginFromRef" type="info">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
// 引入非对称加密
import { encryptor } from '@/utils/ebcryot.js'
import { steLocal } from '@/utils/local'
export default {
  data() {
    return {
      loginFrom: {
        // mobile: '15964210515',
        // password: '123456'
        mobile: '',
        password: ''
      },
      loginRules: {
        mobile: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          { min: 3, max: 14, message: '长度在 3 到 14 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6到 15 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['login']),
    // 重置表单内容
    resetLoginFromRef() {
      this.$refs.loginFprmRef.resetFields()
    },
    logins() {
      // 登录
      this.$refs.loginFprmRef.validate(valid => {
        if (!valid) return false // 表单预验证
        var rsaPassWord = encryptor.encrypt(this.loginFrom.password) // 加密
        this.loginFrom.password = rsaPassWord
        console.log(this.loginFrom)
        localStorage.setItem('ming', this.loginFrom.mobile)
        this.login(this.loginFrom)
          .then(() => {
            this.$message.success('登录成功')
            this.$router.push('/')
            localStorage.setItem('roleMobile', this.loginFrom.mobile)
          })
          .catch(error => {
            console.log(error)
            return this.$message.error('登录失败')
          })

        // const { data: res } = await this.$http.post('login', this.loginFrom)
        // if (res.meta.status !== 200) return this.$message.error('登录失败')
        // this.$message.success('登录成功')
        // console.log(res)
        // window.sessionStorage.setItem('token', res.data.token)
        // this.$router.push('/home')
      })
    }
  }
}
</script>
<style lang="less" scoped>
.login_container {
  // background: red;
  // background-image: url(https://qiniu.haiwojiamei.com/office/login-bg.jpg);
  background-image: url(https://qiniu.haiwojiamei.com/office/login-bg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .avatar_box {
    height: 77px;
    width: 81px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-195%, -20%);
    border: 1px solid #eee;
    padding: 10px;
    box-shadow: 0 0 10px #eee;
    background-color: white;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}
.login_from {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: flex-end;
}
.pen {
  position: absolute;
  top: 8px;
  left: 152px;
  font-size: 20px;
  font-weight: bold;
}
</style>
