import axios from './index'
// 获取员工列表
export const GetPersonnelList = params => {
  return axios.request({
    url: '/user/info/list',
    method: 'get',
    params
  })
}
// 上传图片至库

export const AddImg = data => {
  return axios.request({
    url: '/user/upload',
    method: 'post',
    data
  })
}
// 保存信息
export const SaveIn = data => {
  return axios.request({
    url: '/user/info/save',
    method: 'post',
    data
  })
}
// 获取权限列表
export const GetPermission = () => {
  return axios.request({
    url: '/permission/get',
    method: 'get'
  })
}
// 添加合同信息

export const AddContract = data => {
  return axios.request({
    url: '/user/info/contract',
    method: 'post',
    data
  })
}
// 添加福利信息

export const AddWelfare = data => {
  return axios.request({
    url: '/user/info/welfare',
    method: 'post',
    data
  })
}
// 获取角色关系列表

export const GetParent = data => {
  return axios.request({
    url: '/role/get',
    method: 'get',
    data
  })
}
// 获取角色信息

export const GetRoleone = data => {
  return axios.request({
    url: `/role/one?id=${data}`,
    method: 'get',
    data
  })
}
// 删除角色信息

export const GetRoleDel = data => {
  return axios.request({
    url: `/role/del?id=${data}`,
    method: 'get'

  })
}
// 添加角色信息

export const RoleAdd = data => {
  return axios.request({
    url: '/role/add',
    method: 'post',
    data
  })
}
// 修改角色信息

export const RoleEdit = data => {
  return axios.request({
    url: '/role/edit',
    method: 'post',
    data
  })
}
// 获取权限信息

export const GetLimits = data => {
  return axios.request({
    url: '/permission/get',
    method: 'get',
    data
  })
}
// 添加权限

export const AddDispatch = data => {
  return axios.request({
    url: '/role/dispatch',
    method: 'post',
    data
  })
}
// 人事添加角色信息
export const AddDUserAssign = data => {
  return axios.request({
    url: '/user/assign',
    method: 'post',
    data
  })
}

// 获取人事组织架构树
export const Organtree = data => {
  return axios.request({
    url: '/organization/node/tree',
    method: 'get',
    data
  })
}

// 添加组织节点
export const Organcreate = data => {
  return axios.request({
    url: '/organization/node/create',
    method: 'post',
    data
  })
}

// 添加岗位节点 
export const Organrole = data => {
  return axios.request({
    url: '/organization/role/create',
    method: 'post',
    data
  })
}

// 获取人事组织架构树
export const Organtreenode = params => {
  return axios.request({
    url: '/organization/node/tree?type=node',
    method: 'get',
    params
  })
}

// 删除岗位 
export const Orgroledelete = data => {
  return axios.request({
    url: '/organization/role/delete',
    method: 'post',
    data
  })
}

// 删除组织 
export const Orgnodedelete = data => {
  return axios.request({
    url: '/organization/node/delete',
    method: 'post',
    data
  })
}

// 添加组织项目 
export const Orgproject = data => {
  return axios.request({
    url: '/organization/project/create',
    method: 'post',
    data
  })
}

// 编辑组织下的项目 
export const Orgeditproject = data => {
  return axios.request({
    url: '/organization/project/edit',
    method: 'post',
    data
  })
}

// 获取主体公司 
export const Orgcompany = data => {
  return axios.request({
    url: '/organization/company/list',
    method: 'get',
    data
  })
}

// 获取组织项目列表 
export const Orgprojectlist = params => {
  return axios.request({
    url: '/organization/project/list',
    method: 'get',
    params
  })
}
// 获取组织项目合同列表 
export const Orgcontractlist = params => {
  return axios.request({
    url: '/organization/project/contract/list',
    method: 'get',
    params
  })
}

// 绑定组织项目合同 
export const Orgbind = data => {
  return axios.request({
    url: '/organization/project/contract/bind',
    method: 'post',
    data
  })
}

// 获取组织项目合同类型列表

export const Orgtypelist = params => {
  return axios.request({
    url: '/organization/project/contract/type/list',
    method: 'get',
    params
  })
}

// 获取岗位人员列表  
export const Orguserlist = params => {
  return axios.request({
    url: '/organization/role/user/list',
    method: 'get',
    params
  })
}

// 绑定岗位人员列表 
export const Orgbinduesr = data => {
  return axios.request({
    url: '/organization/role/user/bind',
    method: 'post',
    data
  })
}

// 删除组织项目 
export const Orgdelete = data => {
  return axios.request({
    url: '/organization/project/delete',
    method: 'post',
    data
  })
}

// 员工库获取组织已绑定员工列表 
export const Nodeuserlist = params => {
  return axios.request({
    url: '/organization/node/user/list',
    method: 'get',
    params
  })
}

// 员工库待绑定 
export const Nodeuserusual = params => {
  return axios.request({
    url: '/organization/node/user/usual',
    method: 'get',
    params
  })
}

// 员工库绑定员工 
export const Nodebind = data => {
  return axios.request({
    url: '/organization/node/user/bind',
    method: 'post',
    data
  })
}

// 组织节点编辑 
export const Orgedit = data => {
  return axios.request({
    url: '/organization/node/edit',
    method: 'post',
    data
  })
}

// 新建组织内项目合同 
export const OrgCont = data => {
  return axios.request({
    url: '/organization/project/contract/create',
    method: 'post',
    data
  })
}

// 编辑岗位 
export const Orgeditgang = data => {
  return axios.request({
    url: '/organization/role/edit',
    method: 'post',
    data
  })
}


// 获取职级列表  
export const Orglevels = params => {
  return axios.request({
    url: '/organization/user/role/levels',
    method: 'get',
    params
  })
}

// 获取用户职级详情 
export const Orgroleinfo = params => {
  return axios.request({
    url: '/organization/user/role/addition/info',
    method: 'get',
    params
  })
}

// 设置职级 
export const Orgroleset = data => {
  return axios.request({
    url: '/organization/user/role/addition/set',
    method: 'post',
    data
  })
}